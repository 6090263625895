/* eslint-disable no-unused-vars */

const Reload = () => import ('@/views/Reload')
const UploadDemo = () => import ('@/views/UploadDemo')
const MapDemo = () => import ('@/views/MapDemo')
const Amap = () => import ('@/views/Amap')

export default {
  route:[
    {
      path: "reload",
      name: "reload",
      component: Reload
    },
    {
      path: "uploadDemo",
      name: "uploadDemo",
      component: UploadDemo
    },
    {
      path: "mapDemo",
      name: "地图",
      component: MapDemo
    },
    {
      path: "amap",
      name: "地图组件",
      component: Amap
    },
    
  ]
};
