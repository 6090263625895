/* eslint-disable no-unused-vars */
const ChannelMarket = () => import ('@/components/market/ChannelMarket')
const Distribution = () => import ('@/components/market/Distribution')
const GroupMarket = () => import ('@/components/market/GroupMarket')
const TasksMarket = () => import ('@/components/market/TasksMarket')


export default {
  route:[
    {
      path: "channelMarket",
      name: "分销池",
      component: ChannelMarket
    },
    {
      path: "distribution",
      name: "分销任务",
      component: Distribution
    },
    {
      path: "groupMarket",
      name: "分销群管理",
      component: GroupMarket
    },
    {
      path: "tasksMarket",
      name: "分销任务分工",
      component: TasksMarket
    },
  ]
};
