/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const ideaList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/idea/getPageList",
    method: "post",
    data: params
  });
};

export const ideaLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/idea/detail",
    method: "post",
    data: params
  });
};

export const ideaFinish = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/idea/edit",
    method: "post",
    data: params
  });
};

export const ideaDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/idea/delete",
    method: "post",
    data: params
  });
};

export const dictSelectAll = function(params) {
  return axios({
    url: BaseUrl + "common/dict/selectAll",
    method: "post",
    data: params
  });
};

export default {
  ideaList,
  ideaLoad,
  ideaFinish,
  ideaDelete,
  dictSelectAll
};
