/* eslint-disable no-unused-vars */
const FuseOrder = () => import ('@/components/order/FuseOrder')
const FuseUser = () => import ('@/components/order/FuseUser')

export default {
  route:[
    {
      path: "fuseOrder",
      name: "派发订单",
      component: FuseOrder
    },
    {
      path: "fuseUser",
      name: "号卡激活员",
      component: FuseUser
    }
  ]
};
