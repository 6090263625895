/* eslint-disable no-unused-vars */
const ResolveMessage = () => import ('@/components/task/ResolveMessage')
const MyTask = () => import ('@/components/task/MyTask')
const Allow = () => import ('@/components/task/Allow')
const Current = () => import ('@/components/task/Current')
const TaskAllocation = () => import ('@/components/task/TaskAllocation')
// // const MyTask = () => import ('@/components/task/myTask/MyTask')
// const Dist = () => import ('@/components/task/myTask/Dist')

export default {
  route:[
    {
      path: "resolveMessage",
      name: "分解信息",
      component: ResolveMessage,
    },
    {
      path: "myTask",
      name: "我的任务",
      component: MyTask,
    },
    {
      path: "taskAllocation",
      name: "任务分配",
      component: TaskAllocation
    },
    {
      path: "allow",
      name: "执行任务",
      component: Allow
    },
    {
      path: "current",
      name: "当前任务",
      component: Current
    },
    
    // {
    //   path: "/school",
    //   component: null,
    //   name:'教师中心 ',
    //   redirect: '/school/schinfo',
    //   children: [
    //     { 
    //       path: "schinfo",
    //       name: "个人信息 ", 
    //       component: SchInfo 
    //     },
    //   ]
    // }
  ]
};
