/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/littleMoney";

const state = {
    littleMoneyList:[],
    littleMoneyTotal: 0,
};

// actions
const actions = {
  async getLittleMoneyList({ commit, state }, params) {
    let result = await api.postLittleMoneyList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 详情
  async getLittleMoneyLoad({ commit, state }, params) {
    return await api.postLittleMoneyLoad(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.littleMoneyList = list;
    state.littleMoneyTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
