/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/account/account";

// actions
const actions = {
  async login({ commit, state }, params) {
    return await api.login(params);
  },
  async ceoLogin({ commit, state }, params) {
    return await api.ceoLogin(params);
  },
  async membersUserPassword({ commit, state }, params) {
    return await api.membersUserPassword(params);
  },
  async getUserInfo({ commit, state }, params) {
    return await api.getUserInfo(params);
  },
  async memberAppGetUserInfo({ commit, state }, params) {
    return await api.memberAppGetUserInfo(params);
  },
  async membersAppEdit({ commit, state }, params) {
    return await api.membersAppEdit(params);
  },
};

export default {
  namespaced: true,
  actions,
};
