/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/college";

const state = {
  collegeList:[],
  collegeTotal: 0,
};

// actions
const actions = {
  async schoolFadeGetPageList({ commit, state }, params) {
    let result = await api.schoolFadeGetPageList(params);
    commit("saveCollegeList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async membersSchoolDistAppUserDists({ commit, state }, params) {
    return await api.membersSchoolDistAppUserDists(params);
  },
  async commonDictList({ commit, state }, params) {
    return await api.commonDictList(params);
  },
  async membersMajorAdd({ commit, state }, params) {
    return await api.membersMajorAdd(params);
  },
  async membersMajorSelectAll({ commit, state }, params) {
    return await api.membersMajorSelectAll(params);
  },
  async membersMajorEdit({ commit, state }, params) {
    return await api.membersMajorEdit(params);
  },
  async membersMajorDeleteFade({ commit, state }, params) {
    return await api.membersMajorDeleteFade(params);
  },
  async membersSchoolFadeSelectAll({ commit, state }, params) {
    return await api.membersSchoolFadeSelectAll(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveCollegeList(state, { list, total }) {
    state.collegeList = list;
    state.collegeTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
