/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 列表 */
export const membersDormitoryGetDormitoryTree = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/getDormitoryTree",
    method: "post",
    data: params
  });
};

/** 添加分类 */
export const membersDormitoryAddDormitory = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/addDormitory",
    method: "post",
    data: params
  });
};

/** 添加分类回显数据 */
export const membersDormitoryGetEdit = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/getEdit",
    method: "post",
    data: params
  });
};

/** 修改分类 */
export const membersDormitoryEditDormitory = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/editDormitory",
    method: "post",
    data: params
  });
};

/** 删除 */
export const membersDormitoryDeleteDormitory = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/deleteDormitory",
    method: "post",
    data: params
  });
};

/** 套餐选取宿舍 */
export const membersDormitoryGetList = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/getList",
    method: "post",
    data: params
  });
};

/** 个人中心选取宿舍 */
export const membersDormitoryGetListD = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/getListD",
    method: "post",
    data: params
  });
};

/** 获取宿舍信息 */
export const membersDormitoryGetDormitory = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/getDormitory",
    method: "post",
    data: params
  });
};

/** 个人保存宿舍 */
export const membersDormitoryUserAdd = function(params) {
  return axios({
    url: BaseUrl + "members/dormitory/user/add",
    method: "post",
    data: params
  });
};

/** 个人保存宿舍 */
export const membersSchoolGetSchoolDistTree = function(params) {
  return axios({
    url: BaseUrl + "members/school/getSchoolDistTree",
    method: "post",
    data: params
  });
};

/** 个人保存宿舍 */
export const membersSchoolDistLoad = function(params) {
  return axios({
    url: BaseUrl + "members/schoolDist/load",
    method: "post",
    data: params
  });
};

export default {
  membersDormitoryGetDormitoryTree,
  membersDormitoryGetEdit,
  membersDormitoryAddDormitory,
  membersDormitoryEditDormitory,
  membersDormitoryDeleteDormitory,
  membersDormitoryGetList,
  membersDormitoryGetListD,
  membersDormitoryGetDormitory,
  membersDormitoryUserAdd,
  membersSchoolGetSchoolDistTree,
  membersSchoolDistLoad,
};