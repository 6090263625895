/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/apartment";

const state = {
  apartmentList:[],
  apartmentTotal: 0,
};

// actions
const actions = {
  async membersDormitoryGetDormitoryTree({ commit, state }, params) {
    let result = await api.membersDormitoryGetDormitoryTree(params);
    commit("saveApartmentList", {
      list: result,
      total: result.total,
    });
    return result;
  },
  async membersDormitoryAddDormitory({ commit, state }, params) {
    return await api.membersDormitoryAddDormitory(params);
  },
  async membersDormitoryGetEdit({ commit, state }, params) {
    return await api.membersDormitoryGetEdit(params);
  },
  async membersDormitoryEditDormitory({ commit, state }, params) {
    return await api.membersDormitoryEditDormitory(params);
  },
  async membersDormitoryDeleteDormitory({ commit, state }, params) {
    return await api.membersDormitoryDeleteDormitory(params);
  },
  async membersDormitoryGetList({ commit, state }, params) {
    return await api.membersDormitoryGetList(params);
  },
  async membersDormitoryGetListD({ commit, state }, params) {
    return await api.membersDormitoryGetListD(params);
  },
  async membersDormitoryGetDormitory({ commit, state }, params) {
    return await api.membersDormitoryGetDormitory(params);
  },
  async membersDormitoryUserAdd({ commit, state }, params) {
    return await api.membersDormitoryUserAdd(params);
  },
  async membersSchoolGetSchoolDistTree({ commit, state }, params) {
    return await api.membersSchoolGetSchoolDistTree(params);
  },
  async membersSchoolDistLoad({ commit, state }, params) {
    return await api.membersSchoolDistLoad(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveApartmentList(state, { list, total }) {
    state.apartmentList = list;
    state.apartmentTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
