/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/system/dept";

const state = {
  deptList:[],
  deptTotal: 0,
};

// actions
const actions = {
  async commonDeptist({ commit, state }, params) {
    let result = await api.commonDeptist(params);
    commit("saveDeptList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async commonDeptAdd({ commit, state }, params) {
    return await api.commonDeptAdd(params);
  },
  async commonDeptDelete({ commit, state }, params) {
    return await api.commonDeptDelete(params);
  },
  async commonDeptEdit({ commit, state }, params) {
    return await api.commonDeptEdit(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveDeptList(state, { list, total }) {
    state.deptList = list;
    state.deptTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
