/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const aboutUsList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/aboutUs/list",
    method: "post",
    data: params
  });
};

export const aboutUsAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/aboutUs/add",
    method: "post",
    data: params
  });
};

export const aboutUsDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/aboutUs/delete",
    method: "post",
    data: params
  });
};

export const aboutUsLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/aboutUs/load",
    method: "post",
    data: params
  });
};

export const aboutUsEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/aboutUs/edit",
    method: "post",
    data: params
  });
};

export default {
  aboutUsList,
  aboutUsAdd,
  aboutUsLoad,
  aboutUsDelete,
  aboutUsEdit,
};
