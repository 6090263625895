/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/pop";

const state = {
  popList:[],
  popTotal: 0,
};

// actions
const actions = {
  async systemPopList({ commit, state }, params) {
    let result = await api.systemPopList(params);
    commit("savePopList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async systemPopAdd({ commit, state }, params) {
    return await api.systemPopAdd(params);
  },
  async systemPopDelete({ commit, state }, params) {
    return await api.systemPopDelete(params);
  },
  async systemPopLoad({ commit, state }, params) {
    return await api.systemPopLoad(params);
  },
  async systemPopEdit({ commit, state }, params) {
    return await api.systemPopEdit(params);
  },
};

//getters
const getters = {};

const mutations = {
  savePopList(state, { list, total }) {
    state.popList = list;
    state.popTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
