/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/lostProperty";

const state = {
    goodList:[],
    goodTotal: 0,
};

// actions
const actions = {
  async getPagesList({ commit, state }, params) {
    let result = await api.getPagesList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async goodsAdd({ commit, state }, params) {
    return await api.goodsAdd(params);
  },
  async goodsEdit({ commit, state }, params) {
    return await api.goodsEdit(params);
  },
  async goodsLoad({ commit, state }, params) {
    return await api.goodsLoad(params);
  },
  async goodsDelete({ commit, state }, params) {
    return await api.goodsDelete(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.goodList = list;
    state.goodTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
