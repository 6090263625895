/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const commonNoticeList = function(params) {
  return axios({
    url: BaseUrl + "common/notice/list",
    method: "post",
    data: params
  });
};

export const commonNoticeAdd = function(params) {
  return axios({
    url: BaseUrl + "common/notice/add",
    method: "post",
    data: params
  });
};

export const commonNoticeDelete = function(params) {
  return axios({
    url: BaseUrl + "common/notice/delete",
    method: "post",
    data: params
  });
};

export const commonNoticeEdit = function(params) {
  return axios({
    url: BaseUrl + "common/notice/edit",
    method: "post",
    data: params
  });
};

export const commonNoticeLoad = function(params) {
  return axios({
    url: BaseUrl + "common/notice/noLogin/load",
    method: "post",
    data: params
  });
};

export default {
  commonNoticeList,
  commonNoticeAdd,
  commonNoticeDelete,
  commonNoticeEdit,
  commonNoticeLoad
};
