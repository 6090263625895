/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/school";

const state = {
  schoolList:[],
  schoolTotal: 0,
};

// actions
const actions = {
  async membersSchoolList({ commit, state }, params) {
    let result = await api.membersSchoolList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async membersSchoolAdd({ commit, state }, params) {
    return await api.membersSchoolAdd(params);
  },
  async membersSchoolLoad({ commit, state }, params) {
    return await api.membersSchoolLoad(params);
  },
  async membersSchoolDelete({ commit, state }, params) {
    return await api.membersSchoolDelete(params);
  },
  async membersSchoolEdit({ commit, state }, params) {
    return await api.membersSchoolEdit(params);
  },
  async operateAdminAppItemGetItem({ commit, state }, params) {
    return await api.operateAdminAppItemGetItem(params);
  },
  async operateAdminAppItemSchoolAddList({ commit, state }, params) {
    return await api.operateAdminAppItemSchoolAddList(params);
  },
  async membersSetCeo({ commit, state }, params) {
    return await api.membersSetCeo(params);
  },
  
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.schoolList = list;
    state.schoolTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
