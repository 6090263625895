/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/service/broadbandService";

const state = {
  networkList:[],
  networkTotal: 0,
};
const mutations = {
  saveList(state, { list, total }) {
    state.networkList = list;
    state.networkTotal = total;
  },
};
// actions
const actions = {
  async repairList({ commit, state }, params) {
    let result = await api.repairList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async repairEdit({ commit, state }, params) {
    return await api.repairEdit(params);
  },
  async repairAdd({ commit, state }, params) {
    return await api.repairAdd(params);
  },
  async repairLoad({ commit, state }, params) {
    return await api.repairLoad(params);
  },
  async repairDelete({ commit, state }, params) {
    return await api.repairDelete(params);
  },
  async repairAssign({ commit, state }, params) {
    return await api.repairAssign(params);
  },
  async repairVisit({ commit, state }, params) {
    return await api.repairVisit(params);
  },
  async getAppRoleUsers({ commit, state }, params) {
    return await api.getAppRoleUsers(params);
  },
  // 维修详情
  async repairInfo({commit,state},params) {
    return await api.repairInfo(params)
  }
};

//getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
