/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询end */
export const getActivityList = function(params) {
  return axios({
    url: BaseUrl + "club//activity/app/getActivityList",
    method: "post",
    data: params
  });
};

/** 添加 */
export const activityAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/clubActivity/add",
    method: "post",
    data: params
  });
};

/** 编辑 */
export const activityEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/clubActivity/edit",
    method: "post",
    data: params
  });
};

/** 详情 */
export const activityLoad = function(params) {
  return axios({
    url: BaseUrl + "club/activity/app/loadActivity",
    method: "post",
    data: params
  });
};

/** 报名列表 */
export const activityGetUserList = function(params) {
  return axios({
    url: BaseUrl + "club/activity/user/app/getUserList",
    method: "post",
    data: params
  });
};

/** 打卡列表end */
export const activityGetClockList = function(params) {
  return axios({
    url: BaseUrl + "club/activity/user/app/getClockList",
    method: "post",
    data: params
  });
};

/** 活动奖励信息end */
export const prizeList = function(params) {
  return axios({
    url: BaseUrl + "club/prize/user/app/activityList",
    method: "post",
    data: params
  });
};

/** 删除 */
export const activityDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/clubActivity/delete",
    method: "post",
    data: params
  });
};

export default {
  getActivityList,
  prizeList,
  activityAdd,
  activityEdit,
  activityLoad,
  activityGetUserList,
  activityGetClockList,
  activityDelete,
};
