/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/notice";

const state = {
  noticeList:[],
  noticeTotal: 0,
};

// actions
const actions = {
  async commonNoticeList({ commit, state }, params) {
    let result = await api.commonNoticeList(params);
    commit("saveNoticeList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async commonNoticeAdd({ commit, state }, params) {
    return await api.commonNoticeAdd(params);
  },
  async commonNoticeDelete({ commit, state }, params) {
    return await api.commonNoticeDelete(params);
  },
  async commonNoticeEdit({ commit, state }, params) {
    return await api.commonNoticeEdit(params);
  },
  async commonNoticeLoad({ commit, state }, params) {
    return await api.commonNoticeLoad(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveNoticeList(state, { list, total }) {
    state.noticeList = list;
    state.noticeTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
