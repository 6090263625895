/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/guide";

const state = {
  guideList:[],
  guideTotal: 0,
};

// actions
const actions = {
  async systemGuideList({ commit, state }, params) {
    let result = await api.systemGuideList(params);
    commit("saveGuideList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async systemGuideAdd({ commit, state }, params) {
    return await api.systemGuideAdd(params);
  },
  async systemGuideDelete({ commit, state }, params) {
    return await api.systemGuideDelete(params);
  },
  async systemGuideLoad({ commit, state }, params) {
    return await api.systemGuideLoad(params);
  },
  async systemGuideEdit({ commit, state }, params) {
    return await api.systemGuideEdit(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveGuideList(state, { list, total }) {
    state.guideList = list;
    state.guideTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
