/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const membersSchoolList = function(params) {
  return axios({
    url: BaseUrl + "members/school/list",
    method: "post",
    data: params
  });
};

export const membersSchoolLoad = function(params) {
  return axios({
    url: BaseUrl + "members/school/load",
    method: "post",
    data: params
  });
};

export const membersSchoolAdd = function(params) {
  return axios({
    url: BaseUrl + "members/school/add",
    method: "post",
    data: params
  });
};

export const membersSchoolDelete = function(params) {
  return axios({
    url: BaseUrl + "members/school/delete",
    method: "post",
    data: params
  });
};

export const membersSchoolEdit = function(params) {
  return axios({
    url: BaseUrl + "members/school/edit",
    method: "post",
    data: params
  });
};
// 设置ceo账号
export const membersSetCeo = function(params) {
  return axios({
    url: BaseUrl + "members/app/setCeo",
    method: "post",
    data: params
  });
};

// 学校是否分配权限获取
export const operateAdminAppItemGetItem = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItem/getItem",
    method: "post",
    data: params
  });
};

// 学校权限保存
export const operateAdminAppItemSchoolAddList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItemSchool/addList",
    method: "post",
    data: params
  });
};

export default {
  membersSchoolList,
  membersSchoolAdd,
  membersSchoolDelete,
  membersSchoolEdit,
  membersSchoolLoad,
  operateAdminAppItemGetItem,
  operateAdminAppItemSchoolAddList,
  membersSetCeo
};