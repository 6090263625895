/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

// 获取人员列表
export const repairList = function(params) {
  return axios({
    url: BaseUrl + "members/repair/list",
    method: "post",
    data: params
  });
};
// 手机号查询名称
export const getRepairUser = function(params) {
  return axios({
    url: BaseUrl + "members/repair/getRepairUser",
    method: "post",
    data: params
  });
};
// 添加
export const repairAdd = function(params) {
  return axios({
    url: BaseUrl + "members/repair/add",
    method: "post",
    data: params
  });
};

export const repairDelete = function(params) {
  return axios({
    url: BaseUrl + "members/repair/delete",
    method: "post",
    data: params
  });
};

export const repairEdit = function(params) {
  return axios({
    url: BaseUrl + "members/repair/edit",
    method: "post",
    data: params
  });
};

export const repairGetSchool = function(params) {
  return axios({
    url: BaseUrl + "members/repair/getSchool",
    method: "post",
    data: params
  });
};

export default {
  repairList,
  getRepairUser,
  repairAdd,
  repairDelete,
  repairEdit,
  repairGetSchool
};
