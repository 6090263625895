/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const commonDeptist = function(params) {
  return axios({
    url: BaseUrl + "common/dept/list",
    method: "post",
    data: params
  });
};

export const commonDeptAdd = function(params) {
  return axios({
    url: BaseUrl + "common/dept/add",
    method: "post",
    data: params
  });
};

export const commonDeptDelete = function(params) {
  return axios({
    url: BaseUrl + "common/dept/delete",
    method: "post",
    data: params
  });
};

export const commonDeptEdit = function(params) {
  return axios({
    url: BaseUrl + "common/dept/edit",
    method: "post",
    data: params
  });
};

export default {
  commonDeptist,
  commonDeptAdd,
  commonDeptDelete,
  commonDeptEdit,
};
