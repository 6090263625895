/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 报修列表 */
export const repairList = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/list",
    method: "post",
    data: params
  });
};

/** 报修详情 */
export const repairLoad = function(params) {
  return axios({
    url: BaseUrl + "app/repair/load",
    method: "post",
    data: params
  });
};

/** 查找指定app角色的所有用户 */
export const getAppRoleUsers = function(params) {
  return axios({
    url: BaseUrl + "members/app/getAppRoleUsers",
    method: "get",
    params: params
  });
};

/** 分配报修工单 */
export const repairAssign = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/assign",
    method: "post",
    data: params
  });
};

/** 添加回访记录 */
export const repairVisit = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/visit",
    method: "post",
    data: params
  });
};


/** 编辑工单 */
export const repairEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/edit",
    method: "post",
    data: params
  });
};

/** 添加工单 */
export const repairAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/add",
    method: "post",
    data: params
  });
};

export default {
  repairList,
  repairLoad,
  getAppRoleUsers,
  repairAssign,
  repairVisit,
  repairEdit,
  repairAdd
};
