/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/repair";

const state = {
  netList: [],
  netTotal: 0,
};

const mutations = {
  saveList(state, { list, total }) {
    state.netList = list;
    state.netTotal = total;
  },
};

// actions
const actions = {
  async repairList({ commit, state }, params) {
    let result = await api.repairList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async repairLoad({ commit, state }, params) {
    return await api.repairLoad(params);
  },
  async getAppRoleUsers({ commit, state }, params) {
    return await api.getAppRoleUsers(params);
  },
  async repairAssign({ commit, state }, params) {
    return await api.repairAssign(params);
  },
  async repairVisit({ commit, state }, params) {
    return await api.repairVisit(params);
  },
  async repairEdit({ commit, state }, params) {
    return await api.repairEdit(params);
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
