/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/creative";

const state = {
  creativeList:[],
  creativeTotal: 0,
};

// actions
const actions = {
  async ideaList({ commit, state }, params) {
    let result = await api.ideaList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async ideaLoad({ commit, state }, params) {
    return await api.ideaLoad(params);
  },
  async ideaFinish({ commit, state }, params) {
    return await api.ideaFinish(params);
  },
  async ideaDelete({ commit, state }, params) {
    return await api.ideaDelete(params);
  },
  async dictSelectAll({ commit, state }, params) {
    return await api.dictSelectAll(params);
  },
  

 
};

//getters
const getters = {};

const mutations = {
  saveList(state, { list, total }) {
    state.creativeList = list;
    state.creativeTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
