/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询end */
export const clubList = function(params) {
  return axios({
    url: BaseUrl + "club/app/clubList",
    method: "post",
    data: params
  });
};

/** 社团换届申请列表 分页查询end */
export const applyList = function(params) {
  return axios({
    url: BaseUrl + "club/apply/app/applyList",
    method: "post",
    data: params
  });
};

/** 社团详情 历任社长end */
export const presidentList = function(params) {
  return axios({
    url: BaseUrl + "club/president/app/getPagelist",
    method: "post",
    data: params
  });
};

/** 换届审核详情end */
export const applyResult = function(params) {
  return axios({
    url: BaseUrl + "club/apply/app/getDetail",
    method: "post",
    data: params
  });
};

/** 换届审核通过end */
export const applySuccess = function(params) {
  return axios({
    url: BaseUrl + "club/apply/app/success",
    method: "post",
    data: params
  });
};

/** 换届审核不通过end */
export const applyFail = function(params) {
  return axios({
    url: BaseUrl + "club/apply/app/edit",
    method: "post",
    data: params
  });
};

/** 添加 */
export const clubAdd = function(params) {
  return axios({
    url: BaseUrl + "club/app/add",
    method: "post",
    data: params
  });
};

/** 编辑 */
export const clubEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/club/edit",
    method: "post",
    data: params
  });
};

/** 详情 */
export const clubLoad = function(params) {
  return axios({
    url: BaseUrl + "club/app/loadClub",
    method: "post",
    data: params
  });
};

/** 删除 */
export const clubDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/club/delete",
    method: "post",
    data: params
  });
};

/** 任命社长搜索end */
export const getUsers = function(params) {
  return axios({
    url: BaseUrl + "members/app/getUsers",
    method: "post",
    data: params
  });
};

/** 社团成员end */
export const userList = function(params) {
  return axios({
    url: BaseUrl + "club/user/app/userList",
    method: "post",
    data: params
  });
};

export default {
  clubList,
  applyList,
  presidentList,
  applyResult,
  applySuccess,
  applyFail,
  clubAdd,
  clubEdit,
  clubLoad,
  clubDelete,
  getUsers,
  userList,
};
