/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/order/fuseOrder";

const state = {
  fuseOrderList:[],
  fuseOrderTotal: 0,
};

// actions
const actions = {
 // 列表
  async getFuseOrderList({ commit, state }, params) {
    let res = await api.postFuseOrder(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
  // 详情
  async getFuseOrderLoad({ commit, state }, params) {
    return await api.postFuseOrderLoad(params);
  },
  // 派发
  async cardOrderAssign({ commit, state }, params) {
    return await api.cardOrderAssign(params);
  },
  // 派发
  async getAppRoleUsers({ commit, state }, params) {
    return await api.getAppRoleUsers(params);
  },
  
};


//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.fuseOrderList = list;
    state.fuseOrderTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
