import { mapActions } from "vuex";
import store from "@/store";
import { log } from "@/common/utils";
export default {
  data: function() {
    return {
      uploadUrl: "oss/oss/upload",
      fileList: [],
      list:[],
    };
  },
  methods: {
    ...mapActions("oss", ["ossDownload"]),
    handleSuccess(file, fileList) {
      if (file.code == "0") {
        this.$message.success("上传成功");

        if(this.fileType == "single" || this.limit == 1){
          this.$emit("input", file.data.url)
        }else{
          this.fileList.push({
            name: file.data.name,
            path: file.data.url
          })
          this.list.push(file.data)
          this.$emit("input", this.list);
        }
      }else{
        let commit = store.commit;
        commit("setError", {
          type: 'upload',
          data:{
            code: file.code,
            message: file.message
          }
        });
      }
    },
    handleRemove(file) {
      if(this.fileType == "single" || this.limit == 1){
        this.$emit("input", '')
      }else{
        this.fileList = this.fileList.filter(item=>item.path != file.path)
        this.list = this.list.filter(item=>item.url != file.path)
        this.$emit("input", this.list);
      }
    },
    beforeRemove(file) {
      if(file.status == "ready") return;
      return this.$confirm(`确定移除 ${file.name}？`);
    }
  }
};
