/* eslint-disable no-unused-vars */
const EnterAduit = () => import ('@/components/enter/EnterAduit')
export default {
  route:[
    {
      path: "enterAduit",
      name: "入驻审核",
      component: EnterAduit
    },
  ]
};
