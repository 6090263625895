/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 兼职列表
export const getPagesList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/getPagesList",
    method: "post",
    data: params
  });
};
// 详情
export const jobGetDetail = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/getDetail",
    method: "post",
    data: params
  });
};
// 添加兼职
export const jobAddJob = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/addJob",
    method: "post",
    data: params
  });
};
// 下架&关闭
export const jobEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/edit",
    method: "post",
    data: params
  });
}; 
// 删除
export const jobDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/delete",
    method: "post",
    data: params
  });
};
// 报名列表
export const jobApply = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/apply/getPageList",
    method: "post",
    data: params
  });
};
// 报名搜索列表
export const getSearchList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/apply/getSearchList",
    method: "post",
    data: params
  });
};

// 评论列表
export const jobComment = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/comment/getPageList",
    method: "post",
    data: params
  });
};
// 重新发布
export const jobLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/job/load",
    method: "post",
    data: params
  });
};

export default {
  getPagesList,
  jobGetDetail,
  jobAddJob,
  jobEdit,
  jobDelete,
  jobApply,
  getSearchList,
  jobComment,
  jobLoad
};
