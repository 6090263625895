/*
 * @Auhtor: 文锋
 * @Date: 2021-05-10 18:31:44
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-10 18:31:44
 * @FilePath: \repair\src\api\account\account.js
 */
/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const login = function(params) {
  return axios({
    url: BaseUrl + "members/sys/login",
    method: "post",
    data: params
  });
};
export const ceoLogin = function(params) {
  return axios({
    url: BaseUrl + "members/sys/ceo/login",
    method: "post",
    data: params
  });
};

export const membersUserPassword = function(params) {
  return axios({
    url: BaseUrl + "members/user/passwd",
    method: "post",
    data: params
  });
};

export const getUserInfo = function(params) {
  return axios({
    url: BaseUrl + "members/repair/getUserInfo",
    method: "post",
    data: params
  });
};

export const memberAppGetUserInfo = function(params) {
  return axios({
    url: BaseUrl + "members/app/getUserInfo",
    method: "post",
    data: params
  });
};

export const membersAppEdit = function(params) {
  return axios({
    url: BaseUrl + "members/app/edit",
    method: "post",
    data: params
  });
};

export default {
  login,
  ceoLogin,
  membersUserPassword,
  getUserInfo,
  memberAppGetUserInfo,
  membersAppEdit,
};
