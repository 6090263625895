/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/organize/OrganizeManage";

const state = {
  storeList:[],
  storeTotal: 0,
};

const mutations = {
  saveList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

// actions
const actions = {
  async clubList({ commit, state }, params) {
    let result = await api.clubList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async applyList({ commit, state }, params) {
    return await api.applyList(params);
  },
  async presidentList({ commit, state }, params) {
    return await api.presidentList(params);
  },
  async applyResult({ commit, state }, params) {
    return await api.applyResult(params);
  },
  async applySuccess({ commit, state }, params) {
    return await api.applySuccess(params);
  },

  async applyFail({ commit, state }, params) {
    return await api.applyFail(params);
  },
  async clubAdd({ commit, state }, params) {
    return await api.clubAdd(params);
  },
  async clubEdit({ commit, state }, params) {
    return await api.clubEdit(params);
  },
  async clubLoad({ commit, state }, params) {
    return await api.clubLoad(params);
  },
  async clubDelete({ commit, state }, params) {
    return await api.clubDelete(params);
  },
  async getUsers({ commit, state }, params) {
    return await api.getUsers(params);
  },
  async userList({ commit, state }, params) {
    return await api.userList(params);
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
