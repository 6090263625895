/* eslint-disable no-unused-vars */
const DictManagement = () => import ('@/components/system/DictManagement')
const MenuManagement = () => import ('@/components/system/MenuManagement')
const RoleManagement = () => import ('@/components/system/RoleManagement')
const DeptManagement = () => import ('@/components/system/DeptManagement')

export default {
  route:[
    {
      path: "dictManagement",
      name: "字典管理",
      component: DictManagement
    },
    {
      path: "menuManagement",
      name: "菜单管理",
      component: MenuManagement
    },
    {
      path: "roleManagement",
      name: "角色管理",
      component: RoleManagement
    },
    {
      path: "deptManagement",
      name: "部门管理",
      component: DeptManagement
    },
  ]
};
