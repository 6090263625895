/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/apply";

const state = {
  applyList:[],
  applyTotal: 0,
};

// actions
const actions = {
  async systemApplyList({ commit, state }, params) {
    let result = await api.appItemList(params);
    commit("saveStoreList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async systemApplyAdd({ commit, state }, params) {
    return await api.appItemAdd(params);
  },
  async systemApplyDelete({ commit, state }, params) {
    return await api.appItemDelete(params);
  },
  async systemApplyLoad({ commit, state }, params) {
    return await api.appItemLoad(params);
  },
  async systemApplyEdit({ commit, state }, params) {
    return await api.appItemEdit(params);
  },
  async systemApplyGetItem({ commit, state }, params) {
    return await api.appItemSchoolGetItem(params);
  },
  async systemApplyAddList({ commit, state }, params) {
    return await api.appItemSchoolAddList(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveStoreList(state, { list, total }) {
    state.applyList = list;
    state.applyTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
