/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/service/allUser";

const state = {
  repaList:[],
  repairTotal: 0,
};

// actions
const actions = {
 // 列表
  async repairList({ commit, state }, params) {
    let res = await api.repairList(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
  // 编辑
  async repairEdit({ commit, state }, params) {
    return await api.repairEdit(params);
  },
  
  async repairDelete({ commit, state }, params) {
    return await api.repairDelete(params);
  },
  async repairAdd({ commit, state }, params) {
    return await api.repairAdd(params);
  },
  async getRepairUser({ commit, state }, params) {
    return await api.getRepairUser(params);
  },
  async repairGetSchool({ commit, state }, params) {
    return await api.repairGetSchool(params);
  },
};


//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.repaList = list;
    state.repairTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
