/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/system/dict";

const state = {
  dictList:[],
  dictTotal: 0,
};

// actions
const actions = {
  async commonDictList({ commit, state }, params) {
    let result = await api.commonDictList(params);
    commit("saveDictList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async commonDictAdd({ commit, state }, params) {
    return await api.commonDictAdd(params);
  },
  async commonDictDelete({ commit, state }, params) {
    return await api.commonDictDelete(params);
  },
  async commonDictEdit({ commit, state }, params) {
    return await api.commonDictEdit(params);
  },
  async commonDictGetDictFCode({ commit, state }, params) {
    return await api.commonDictGetDictFCode(params);
  },
  async commonDictGetDictFPid({ commit, state }, params) {
    return await api.commonDictGetDictFPid(params);
  },
  async commonDictGetAllDictFCode({ commit, state }, params) {
    return await api.commonDictGetAllDictFCode(params);
  },
  async commonDictGetAllDictFPid({ commit, state }, params) {
    return await api.commonDictGetAllDictFPid(params);
  },
  async commonDictGetAllDictTreeFCode({ commit, state }, params) {
    return await api.commonDictGetAllDictTreeFCode(params);
  },
  async commonDictGetAllDictTreeFPid({ commit, state }, params) {
    return await api.commonDictGetAllDictTreeFPid(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveDictList(state, { list, total }) {
    state.dictList = list;
    state.dictTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
