/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/member";

const state = {
  userList:[],
  userTotal: 0,
};

// actions
const actions = {
  async getTeamUserList({ commit, state }, params) {
    let result = await api.getTeamUserList(params);
    commit("saveUserList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async addTeamUser({ commit, state }, params) {
    return await api.addTeamUser(params);
  },
  async deleteTeamUser({ commit, state }, params) {
    return await api.deleteTeamUser(params);
  },
  async editTeamUser({ commit, state }, params) {
    return await api.editTeamUser(params);
  },
  async getSchoolUser({ commit, state }, params) {
    return await api.getSchoolUser(params);
  }
  
};

//getters
const getters = {};

const mutations = {
  saveUserList(state, { list, total }) {
    state.userList = list;
    state.userTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
