/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const systemTreatyList = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/treaty/list",
    method: "post",
    data: params
  });
};

export const systemTreatyAdd = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/treaty/add",
    method: "post",
    data: params
  });
};

export const systemTreatyDelete = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/treaty/delete",
    method: "post",
    data: params
  });
};

export const systemTreatyEdit = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/treaty/edit",
    method: "post",
    data: params
  });
};

export const systemTreatyLoad = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/treaty/load",
    method: "post",
    data: params
  });
};

export default {
  systemTreatyList,
  systemTreatyAdd,
  systemTreatyDelete,
  systemTreatyEdit,
  systemTreatyLoad
};
