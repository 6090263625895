/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 失物招领列表
export const getPagesList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/goods/getPagesList",
    method: "post",
    data: params
  });
};

export const goodsAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/goods/add",
    method: "post",
    data: params
  });
};

export const goodsEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/goods/edit",
    method: "post",
    data: params
  });
};

export const goodsLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/goods/loadDetail",
    method: "post",
    data: params
  });
};

export const goodsDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/goods/delete",
    method: "post",
    data: params
  });
};


export default {
  getPagesList,
  goodsAdd,
  goodsEdit,
  goodsLoad,
  goodsDelete
};
