/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/fleaMarket";

const state = {
    fleaMarketList:[],
    fleaMarketTotal: 0,
};

// actions
const actions = {
  async getFleaMarketList({ commit, state }, params) {
    let result = await api.postFleaMarketList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 详情
  async getFleaMarketLoad({ commit, state }, params) {
    return await api.postFleaMarketLoad(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.fleaMarketList = list;
    state.fleaMarketTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
