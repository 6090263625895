<template>
  <div class="page page-system-home">
    <div class="page-main page">
      <SideBar :subNav="subNav" :defaultOpened="opened"/>
      <div class="page-header cfx" :class="{'on':!url}">
        <div class="user-info fr">
          <el-popover trigger="hover" width="100">
            <div class="tac"><img :src="require('@/assets/images/mp.jpg')" width="100"></div>
            <div class="app btn" slot="reference"><i class="el-icon-mobile-phone"></i>菁星校园公众号</div>
          </el-popover>
          <div class="notice btn">
            <i class="el-icon-bell"></i> 消息中心
          </div>
          <div class="user" @click="userInfo">
            <el-avatar class="user-pic" :size="36" :src="homeUserDetails.headImg?homeUserDetails.headImg+'?x-oss-process=image/resize,m_fill,h_150,w_150':require('@/assets/images/p80x80.jpg')"></el-avatar>
            {{ systemUser }}
          </div>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link btn"><img :src="require('@/assets/images/userMenu_on.svg')" width="20"></span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="">投诉与建议</el-dropdown-item> -->
              <el-dropdown-item @click.native="handleModify">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="logo"><img :src="require('@/assets/images/wxfw.svg')" width="100%"></div>
        <div class="nav cfx">
          <span v-for="(item,index) in menus" :key="item.id" :class="menuIndex == index?'on':''" @click="menuIndex = index,menuChange(item,index)">{{ item.title }}</span>
        </div>
      </div>
      <div class="page-container page" :class="{ on: isSidebarCollapsed}">
        <div class="breadcrumb-container" id="breadcrumb-container">
          <span class="right-icons">
            <i class="el-icon-refresh right-icon" @click="refresh"></i>
          </span>
          <el-breadcrumb separator="/" class="breadcrumb-inner">
            <el-breadcrumb-item>
              {{ curBreadcrumbName }}
            </el-breadcrumb-item>
          </el-breadcrumb>
          <!-- 举报消息 -->
          <!-- <div class="fr hint" v-if="show&& number>0" @click="hint">
            <p>
              您有<span>{{number?number:0}}</span>条举报信息待处理
            </p>
            <img class="hintdel fr" @click="show=false" src="../assets/images/he/operate/hintdel.svg" alt="">
          </div> -->
        </div>
        <div class="content-wrapper page" id="content-wrapper">
          <keep-alive :include="includedComponents">
            <router-view></router-view>
          </keep-alive>
        </div>
      </div>
    </div>

    <el-dialog title="修改密码" :visible.sync="bVisible" width="400px" :close-on-click-modal="false" :modal-append-to-body="false">
      <el-form label-width="80px" ref="psdFormFileds" :model="psdForm" :rules="psdRules" size="small">
        <el-form-item label="旧密码" prop="oldPasswd">
          <el-input clearable placeholder="请输入密码" v-model="psdForm.oldPasswd" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPasswd">
          <el-input clearable placeholder="请输入新密码" v-model="psdForm.newPasswd" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input clearable placeholder="请输入确认密码" v-model="psdForm.confirmPassword" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="bVisible = false">取消</el-button>
        <el-button size="small" type="success" :loading="bLoading" @click="submitModifyPsd">提交</el-button>
      </div>
    </el-dialog>

    <!-- 个人信息详情 -->
    <el-dialog title="个人信息" :visible.sync="userVisible" width="800px" :close-on-click-modal="false" :modal-append-to-body="false" class="user-dialog">
      <el-row >
        <el-col :span="6" style="text-align:right;">
          <div class="fr user-img" v-if="homeUserDetails.extend">
            <el-avatar :size="70" :src="homeUserDetails.headImg?homeUserDetails.headImg+'?x-oss-process=image/resize,m_fill,h_150,w_150':require('@/assets/images/p80x80.jpg')"></el-avatar>
            <div class="user-name">{{ homeUserDetails.realName?homeUserDetails.realName:'- - -' }}</div>
            <div class="user-name"><img v-if="homeUserDetails.extend.roleName=='CEO'" src="@/assets/images/ceo_lable.svg" alt=""></div>
            <div class="user-name"><img v-if="homeUserDetails.extend.roleName=='CTO'" src="@/assets/images/cto_lable.svg" alt=""></div>
            <div class="user-name"><img v-if="homeUserDetails.extend.roleName=='CFO'" src="@/assets/images/cfo_lable.svg" alt=""></div>
            <div class="user-name"><img v-if="homeUserDetails.extend.roleName=='COO'" src="@/assets/images/coo_lable.svg" alt=""></div>
            <div class="user-name"><img v-if="homeUserDetails.extend.roleName=='CHO'" src="@/assets/images/cho_lable.svg" alt=""></div>
          </div>
        </el-col>
        <el-col :span="18">
          <el-form :model="homeUserDetails" label-width="200px" v-if="!!this.homeUserDetails.accountType">
            <el-form-item label="学号" label-width="120px">
              <span>{{ homeUserDetails.account }}</span>
            </el-form-item>
            <el-form-item label="用户名" label-width="120px" v-if="homeUserDetails.extend">
              <span>{{ systemUser }}</span>
            </el-form-item>
            <el-form-item label="昵称" label-width="120px">
              <span>{{ homeUserDetails.nickName }}</span>
            </el-form-item>
            <el-form-item label="学校" label-width="120px">
              <span>{{ homeUserDetails.extend.schoolName }}</span>
            </el-form-item>
            <el-form-item label="学院" label-width="120px">
              <span>{{ homeUserDetails.fade }}</span>
            </el-form-item>
            <el-form-item label="专业" label-width="120px">
              <span>{{ homeUserDetails.major }}</span>
            </el-form-item>
            <el-form-item label="系统角色" label-width="120px">
              <span>{{ homeUserDetails.extend.typeName }}</span>
            </el-form-item>
            <el-form-item label="宿舍" label-width="120px">
              <span>{{ homeUserDetails.address?homeUserDetails.address:'---' }}</span>
              <span style="color:#AFAFAF; margin-left: 150px;">请前往移动端编辑</span>
            </el-form-item>
            <el-form-item label="手机号" label-width="120px">
              <span>{{ homeUserDetails.extend.mobile?homeUserDetails.extend.mobile:'---' }}</span>
              <!-- <span class="change_tel" @click="telVisible = true">更换手机号码</span> -->
            </el-form-item>
            <el-form-item label-width="120px">
              <el-button type="success" class="btn_padding" @click="editVisible = true">编辑个人信息</el-button>
            </el-form-item>
          </el-form>

          <el-form :model="homeUserDetails" label-width="200px" v-else>
            <el-form-item label="用户名" label-width="120px" v-if="homeUserDetails.extend">
              <span>{{ systemUser }}</span>
            </el-form-item>
            <el-form-item label="职位" label-width="120px">
              <span>{{ homeUserDetails.post }}</span>
            </el-form-item>
            <el-form-item label="所属部门" label-width="120px">
              <span>{{ homeUserDetails.extend.deptName }}</span>
            </el-form-item>
            <el-form-item label="管辖区域" label-width="120px">
              <span>{{ homeUserDetails.extend.userAreaName }}</span>
            </el-form-item>
            <el-form-item label="手机号" label-width="120px">
              <span>{{ homeUserDetails.extend.mobile?homeUserDetails.extend.mobile:'---' }}</span>
              <!-- <span class="change_tel" @click="telVisible = true">更换手机号码</span> -->
            </el-form-item>
            
            <el-form-item label-width="120px">
              <!-- <el-button type="success" class="btn_padding" @click="editVisible = true">编辑个人信息</el-button> -->
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- 编辑个人信息 -->
    <el-dialog title="编辑个人信息" :visible.sync="editVisible" width="500px" :close-on-click-modal="false" :before-close="resetHomeUser" :modal-append-to-body="false" class="user-dialog">
      <el-form :model="homeUser" label-width="200px">
        <el-form-item label="头像：" label-width="120px" ref="homeUserFileds">
          <base-upload v-model="homeUser.headImg" fileType="single" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload>
        </el-form-item>
        <el-form-item label="昵称：" label-width="120px">
          <el-input clearable placeholder="请输入昵称" v-model="homeUser.nickName"></el-input>
        </el-form-item>
        
        <el-form-item label-width="120px">
          <el-button size="small" @click="resetHomeUser()">取消</el-button>
          <el-button size="small" type="primary" @click="homeUserEditor">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 更换手机号弹窗 -->
    <el-dialog
      title="更换手机号码"
      append-to-body
      :visible.sync="telVisible"
      width="30%"
      center>
      <!-- <el-form :model="tel" label-width="200px" :rules="telRule" ref="tel">
        <el-form-item label="新手机号码" prop="mobile" label-width="100px">
          <el-input placeholder="请输入新手机号" v-model="tel.mobile" clearable style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code" label-width="100px">
          <el-input placeholder="请输入验证码" v-model="tel.vcode" clearable style="width: 90%">
            <el-button slot="append" type="success" v-if="!isGetCode" @click="checkCode()" style="background:#28B892; color:#fff;">获取验证码</el-button>
            <template slot="append" v-else>{{ time }}秒</template>
          </el-input>
        </el-form-item>
      </el-form> -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="telVisible = false">取消</el-button>
        <el-button type="success" @click="change_tel" class="btn_padding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState, mapActions, mapMutations } from "vuex";
import DropdownMenu from "@/components/common/DropdownMenu.vue";
import BaseUpload from "@/components/common/BaseUpload";
import SideBar from "@/components/common/SideBar.vue";
import { log, removeToken } from "@/common/utils";

export default {
  name: "home",
  components: {
    DropdownMenu,
    SideBar,
    BaseUpload
  },
  data() {
    return {
      homeUserDetails: {
        extend: {
          passport: "admin",
          userName: "管理员",
        },
        post: "经理",
      },
      homeUser:{
        headImg:'',
        nickName:'',
      },
      phoneVisible: false,
      userVisible: false,
      editVisible: false,
      telVisible: false,
      url:'',
      show: true,
      number: '',
      currentTabsName: "/dashboard",
      includedComponents: ["dashboard"],
      bVisible: false,
      bLoading: false,
      systemUser:'admin',
      psdForm:{
        oldPasswd:"",
        newPasswd:"",
        confirmPassword:"",
      },
      menus:[],
      menuIndex:0,
      subNav:[],
      curTitle: '',
      opened:'',
      uploadData:{
        bizType:'',
        bizId:'',
        needStore:'0',
      },
    };
  },
  computed: {
    ...mapState(["error", "reportRandom", "tabsList", "currentTabPath", "curBreadcrumbName", "isSidebarCollapsed"]),
    psdRules() {
      let validatePassword = (rule, value, callback) => {
        if (this.psdForm.newPasswd !== this.psdForm.confirmPassword) {
          callback(new Error('两次输入密码不相同'));
        }
        callback();
      };
      return {
        oldPasswd:[{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPasswd: [
          { required: true, message: "请输入新密码" },
          { min: 6, max: 12, message: "请输入6-12位数字字母组合", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: "请再次输入新密码" },
          { validator: validatePassword, trigger: "blur" }
        ]
      };
    }
  },
  watch: {
    $route: "fetchData",
    error(val) {
      if(val.data.code == 501){
        removeToken()
        this.$router.push({ path: '/login' })
        this.$message.error('token过期,请重新登录')
        return
      }
      this.$message.error(val.data.message)
    },
    tabsList(tabs) {
      let arr = tabs.map((item) => item.templateName);
      this.includedComponents = Array.from(new Set(arr));
    },
    async reportRandom(){
      let res = await this.getNumber({})
      if(res) {
        this.number = res
      }
    }
  },
  
  methods: {
    ...mapActions('report',["getNumber"]),
    ...mapMutations(["setTabsList", "setCurrentTab", "switchToTab", "setProvince"]),
    ...mapActions("systemDict",[ "commonDictGetDictFPid" ]),
    ...mapActions("account", ["membersUserPassword","getUserInfo","memberAppGetUserInfo","membersAppEdit"]),
    ...mapActions("systemMenu",[ "commonMenuGetUserMenuTree","getUserPermission" ]),
    /** 手机号更改请求 */
    change_tel() {
      let obj = {
        id: this.detail_info.id, 
        mobile: this.tel.mobile,
        vcode: this.tel.vcode
      }
      this.telVisible = false
      this.$refs.tel.validate(async (valid) => {
        if (valid) {
          try {
            const res = await this.webMyChangePhone(obj)
            this.$message.success("更改成功")
          } catch (error) {}
        } else {
          this.$message.error('更改失败');
          return false;
        }
      })
      this.self_info()
      // this.$refs.tel.resetFields();
    },
    // 个人信息
    userInfo() {
      this.userVisible = true
    },
    async homeUserEditor(){
      try{
        let res = await this.membersAppEdit({...this.homeUser})
        if(!!res){this.$message.success("保存成功!")}
      }catch(error){}
      this.resetHomeUser()
    },
    resetHomeUser(){
      console.log(111)
      this.homeUser.headImg = ''
      this.homeUser.nickName = ''
      this.editVisible = false
    },
    removeTab(fullPath) {
      let tabs = this.tabsList;
      let activeName = this.currentTabsName;
      if (activeName === fullPath) {
        tabs.forEach((tab, index) => {
          if (tab.fullPath === fullPath) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.fullPath;
              this.$router.push(activeName);
            }
          }
        });
      }
      this.currentTabsName = activeName;
      tabs = tabs.filter((tab) => tab.fullPath !== fullPath);
      this.setTabsList(tabs);
    },
    fetchData() {
      let tabs = this.tabsList;
      let name = this.$route.name;
      let path = this.$route.path;
      let fullPath = this.$route.fullPath;
      let resolved = this.$router.resolve(this.$route.path);
      let templateName = resolved.route.matched[resolved.route.matched.length-1].components.default.name;
      if (path == "/reload") {
        this.$router.go(-1);
        return;
      }
      this.currentTabsName = fullPath;
      //判断是否满足下钻
      if (tabs.some((item) => item.path == path && item.fullPath != fullPath)) {
        tabs.forEach((k) => {
          if (k.name == name) {
            k.name = name;
            k.fullPath = fullPath;
          }
        });
      } else if (!tabs.some((item) => item.fullPath == fullPath)) {
        tabs.push({
          name: name,
          path: path,
          fullPath: fullPath,
          templateName: templateName
        });
      }
      this.setTabsList(tabs);
      this.setCurrentTab({ context: this, fullPath });
    },
    handleClick(tab) {
      this.switchToTab({ context: this, tabIndex: tab.index });
    },
    refresh() {
      let resolved = this.$router.resolve(this.$route.path);
      let templateName = resolved.route.matched[1].components.default.name;
      this.includedComponents = this.includedComponents.filter(
        (item) => item !== templateName
      );
      this.$router.push("/reload");
    },
    // 举报信息
    hint() {
      this.$router.push("/report")
    },
    // 关闭标签页
    closeTabs(type) {
      switch (type) {
        case 1:
          if (this.currentTabsName == "/dashboard") {
            this.$message({ message: "首页不可关闭", type: "warning" });
            return;
          }
          this.removeTab(this.currentTabsName);
          break;
        case 2: {
          let tabsList = this.tabsList.filter(
            (item) =>
              item.fullPath == this.currentTabsName ||
              item.path == "/dashboard"
          );
          this.setTabsList(tabsList);
          break;
        }
        case 3: {
          let tabsList = this.tabsList.filter(
            (item) => item.path == "/dashboard"
          );
          this.currentTabsName = "/dashboard";
          this.$router.push("/dashboard");
          this.setTabsList(tabsList);
          break;
        }
      }
    },

    handleModify(){
      this.bVisible = true
      this.$nextTick(() => {
        this.$refs.psdFormFileds.resetFields();
      })
    },
    submitModifyPsd(){
      this.$refs.psdFormFileds.validate(async (valid) => {
        if (valid) {
          this.bLoading = true
          try {
            await this.$confirm('确认提交修改密码吗？')
            let params = Object.assign({}, this.psdForm)
            await this.membersUserPassword(params)
            this.$message.success('密码修改成功,请重新登录')
            removeToken()
            this.$router.push('/login')
          }catch(e){
            log(e)
          }finally {
            this.bLoading = false
          }
        }
      })
    },
    async loginOut(){
      try {
        await this.$confirm("确定退出登录吗？");
        removeToken()
        this.$router.replace("/login");
      } catch (e) {
        log(e);
        if (e !== "cancel") {
          this.$router.replace("/login");
        }
      }
    },
    async getMenusData(){
      let saas = sessionStorage.getItem('schoolId')
      if(sessionStorage.getItem('appId') == 4 ){
        this.menus = await this.commonMenuGetUserMenuTree({saas})
        this.menuChange(this.menus[0])
      }else {
        this.menus = await this.commonMenuGetUserMenuTree({})
        this.menuChange(this.menus[0])
      }
    },
    menuChange(row){
      console.log(row)
      this.curTitle = row.curTitle
      this.subNav = row.children ? row.children : []
      if(row.children.length){
        let menu = row.children[0]
        // log(menu.children)
        if(menu.children && menu.children.length){
          this.$router.push(menu.children[0].path)
          this.opened = '1'
          log(this.opened)
        }else{
          this.$router.push(menu.path)
          this.opened = ''
        }
      }
    },
    /** 图片格式 */
    handleBeforeUpload(file, callback){
      const isPicture = file.name.includes('jpg') || file.name.includes('png');
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isPicture) {
          this.$message.error('上传图片只能是 jpg/png 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
      }
      log(isPicture)
      callback(isPicture && isLt2M)
    },
  },
  async created(){
    let province = await this.commonDictGetDictFPid({pid: 101001})
    this.setProvince(province)
  },
  async mounted() {
    this.url = window.location.href.includes('ceo') 
    // console.log(this.url)

    // let route = this.$route;
    // if (
    //   !this.tabsList.filter((item) => item.fullPath == route.fullPath).length
    // ) {
    //   let resolved = this.$router.resolve(route.path);
    //   let templateName = resolved.route.matched[1].components.default.name;
    //   this.currentTabsName = route.fullPath;
    //   this.tabsList.push({
    //     name: route.name,
    //     path: route.path,
    //     fullPath: route.fullPath,
    //     templateName: templateName
    //   });
    //   this.setTabsList(this.tabsList);
    //   this.setCurrentTab({
    //     context: this,
    //     name: route.name,
    //     fullPath: route.fullPath
    //   });
    // }
    this.number = await this.getNumber({})
    const res = await this.getUserInfo()
    this.homeUserDetails = res
    // this.homeUserDetails = await this.memberAppGetUserInfo()
    this.systemUser = res.extend.passport
    // console.log(res)
    sessionStorage.setItem('schoolId', res.schoolId)
    if(sessionStorage.getItem('appId') == 4) {
      let permission  = await this.getUserPermission({saas:res.schoolId})
      this.$store.commit('setOA', permission)
    }else {
      let permission  = await this.getUserPermission({})
      this.$store.commit('setOA', permission)
    }
    
    this.getMenusData()

  },
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
.page-system-home {
  .page-main {
    overflow: hidden;
    transition: left 0.3s ease-in-out;
    background: rgba(244, 244, 244, 1);
    .page-header {
      position: relative;
      height: 100px;
      background: #28b892;
      .user-info { display: flex; height:100px; align-items: center; font-size:16px; justify-content: flex-end;  transition: all 0.3s; color:#fff;
        .app,.user,.notice { height:100px; margin-left:25px; line-height:100px; }
        .user { display: flex; align-items: center; justify-content: center; line-height:100px;
          .user-pic { width:36px; height:36px; margin-right:15px; border-radius: 50%; overflow:hidden; }
          .user-name { margin-right:15px; }
        }
        .el-dropdown-link img { margin:0 20px; vertical-align: middle;  }
      }
      .logo { position: relative; width:423px; height:100px; margin-left:10px; display:flex; justify-content: center; align-items: center; }
      .nav { position: absolute; top:0; left:470px; right:420px; height:100px; padding:20px 0; overflow:hidden;
        span { float:left; width:94px; line-height:60px; color:#fff; font-size:16px; cursor: pointer;
          &.on { font-weight: bold; }
        }
      }
      &.on {
        .logo { width:280px; }
        .nav { left:320px; }
      }
    }
    .page-container {
      top: 110px;
      right: 10px;
      left: 190px;
      bottom: 10px;
      .breadcrumb-container {
        .hint { width:250px;height:30px;background:$c-danger;border-radius: 15px;line-height: 30px;padding:0 20px; display: inline-block; color: $c-white;
          .hintdel  { display: inline-block; line-height: 100px; width: 15px; height: 15px;overflow: hidden; margin-top: 8px;}
          p { display: inline-block; }
        }
        height: 40px;
        line-height: 40px;
        i {
          color: #00a199;
          font-size: 1.4em;
        }
        .right-icons {
          cursor: pointer;
          float: right;
          margin-right: 10px;
        }
        .right-icon {
          margin-left: 8px;
        }
        .el-icon-caret-left,
        .el-icon-caret-right {
          font-size: 20px;
          cursor: pointer;
        }
        .breadcrumb-inner {
          display: inline-block;
          color: #475669;
          padding: 0 20px 0 8px;
          border-left: 2px solid #00a199;
        }
      }
      .content-wrapper {
        top: 35px;
        right: 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
      &.on {
        left: 79px;
      }
    }
  }
}
@media screen and (min-width:1360px) and (max-width:1600px){
  .page-system-home {
    .page-main {
      .page-header { 
        .logo { width:300px; }
        .nav { left:340px; right:380px;
          span { width:90px; }
        }
        .user-info {
          .app,.user,.notice { margin-left:15px; cursor: pointer; }
          .el-dropdown-link img { margin:0 10px; }
        }
      }
    }
  }
}

.user-dialog {
  .user-img{ margin-right: 20px; 
    .user-name { font-size: 20px; text-align: center; font-weight: bold; }
  }
}
.change_tel { cursor: pointer; margin-left: 150px; color: #4A90E2 ; text-decoration: underline; }
</style>
