/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/about";

const state = {
  aboutList:[],
  aboutTotal: 0,
};

// actions
const actions = {
  async aboutUsList({ commit, state }, params) {
    let result = await api.aboutUsList(params);
    commit("saveAboutList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async aboutUstAdd({ commit, state }, params) {
    return await api.aboutUstAdd(params);
  },
  async aboutUstDelete({ commit, state }, params) {
    return await api.aboutUstDelete(params);
  },
  async aboutUsLoad({ commit, state }, params) {
    return await api.aboutUsLoad(params);
  },
  async aboutUsEdit({ commit, state }, params) {
    return await api.aboutUsEdit(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveAboutList(state, { list, total }) {
    state.aboutList = list;
    state.aboutTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
