/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/schoolFellow";

const state = {
    schoolFellowList:[],
    schoolFellowTotal: 0,
};

// actions
const actions = {
  async getSchoolFellowList({ commit, state }, params) {
    let result = await api.getSchoolFellowList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 详情
  async socialDetail({ commit, state }, params) {
    return await api.socialDetail(params);
  },
  // 增加
//   async getSchoolNewsAdd({ commit, state }, params) {
//     return await api.postSchoolNewsAdd(params);
//   },
  // 修改
//   async getSchoolNewsEdit({ commit, state }, params) {
//     return await api.postSchoolNewsEdit(params);
//   },
  // 删除
//   async getSchoolNewsDelete({ commit, state }, params) {
//     return await api.postSchoolNewsDelete(params);
//   },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.schoolFellowList = list;
    state.schoolFellowTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
