/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const appItemList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItem/list",
    method: "post",
    data: params
  });
};

export const appItemAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItem/add",
    method: "post",
    data: params
  });
};

export const appItemDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItem/delete",
    method: "post",
    data: params
  });
};

export const appItemEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItem/edit",
    method: "post",
    data: params
  });
};

export const appItemLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItem/load",
    method: "post",
    data: params
  });
};

/** 学校权限获取 */ 
export const appItemSchoolGetItem = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItemSchool/getItem",
    method: "post",
    data: params
  });
};

/** 学校权限保存 */
export const appItemSchoolAddList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/appItemSchool/addList",
    method: "post",
    data: params
  });
};

export default {
  appItemList,
  appItemAdd,
  appItemDelete,
  appItemEdit,
  appItemLoad,
  appItemSchoolGetItem,
  appItemSchoolAddList,
};
