import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

//acccount
import account from "./modules/account/account";
import fleaMarket from "./modules/operate/fleaMarket";
import littleMoney from "./modules/operate/littleMoney";
import schoolNews from "./modules/operate/schoolNews";
import schoolFellow from "./modules/operate/schoolFellow";

import systemDict from "./modules/system/dict";
import systemMenu from "./modules/system/menu";
import systemRole from "./modules/system/role";
import systemDept from "./modules/system/dept";
// basic
import basicNotice from "./modules/basic/notice";
import basicSchool from "./modules/basic/school";
import basicCampus from "./modules/basic/campus";
import systemUser from "./modules/basic/user";
import basicApp from "./modules/basic/app";
import systemAbout from "./modules/basic/about";
import systemVersion from "./modules/basic/version";
import systemApply from "./modules/basic/apply";
import systemTreaty from "./modules/basic/treaty";
import systemGuide from "./modules/basic/guide";
import systemPop from "./modules/basic/pop";
import basicMember from "./modules/basic/member";
import basicApartment from "./modules/basic/apartment";
import basicCollege from "./modules/basic/college";
import creative from "./modules/basic/creative";
import suggest from "./modules/basic/suggestions";



import commonOptions from "./modules/common/options";

import partTime from "./modules/operate/partTime";
import lostProperty from "./modules/operate/lostProperty";
import advertising from "./modules/operate/advertising";
import report from "./modules/operate/report";
import netRepair from "./modules/operate/netRepair";
import campusRepair from "./modules/operate/campusRepair";

// timeTable
import timeTable from "./modules/timeTable/timeTable";
import classManage from "./modules/timeTable/ClassManage";
import termManage from "./modules/timeTable/TermManage";

// orginaze
import organizeManage from "./modules/organize/organizeManage";
import organizeTrends from "./modules/organize/organizeTrends";
import organizeActivity from "./modules/organize/organizeActivity";
// enter
import enterAduit from "./modules/enter/aduit";

import dealOrder from "./modules/finance/dealOrder";
import communOrder from "./modules/finance/communOrder";
import deposit from "./modules/finance/deposit";


import fuseOrder from "./modules/order/fuseOrder";
import fuseUser from "./modules/order/fuseUser";
import broadbandService from "./modules/service/broadbandService";
import realService from "./modules/service/realService";
import campusUser from "./modules/service/campusUser";
import netUser from "./modules/service/NetUser";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
    currentPath: null,
    OA:[],
    pageParams: {},
    tabsList: [
      {
        name: "首页",
        path: "/dashboard",
        fullPath: "/dashboard",
      }
    ],
    reportRandom: Math.random(),
    currentTabPath: "/dashboard",
    curBreadcrumbName: "首页",
    isSidebarCollapsed: false,
    provinceOptions:[],
  },
  modules: {
    campusUser,
    netUser,
    fuseUser,
    broadbandService,
    realService,
    fuseOrder,
    account,
    creative,
    suggest,
    basicApp,
    basicSchool,
    basicNotice,
    basicCampus,
    systemAbout,
    systemVersion,
    systemTreaty,
    systemApply,
    systemGuide,
    systemPop,
    basicMember,
    systemDict,
    systemDict,
    systemMenu,
    systemRole,
    systemUser,
    systemDept,
    commonOptions,
    fleaMarket,
    littleMoney,
    schoolNews,
    schoolFellow,
    partTime,
    lostProperty,
    advertising,
    report,
    netRepair,
    campusRepair,
    timeTable,
    classManage,
    termManage,
    organizeManage,
    organizeTrends,
    organizeActivity,
    enterAduit,
    basicApartment,
    basicCollege,
    dealOrder,
    communOrder,
    deposit
  },
  getters,
  mutations,
  actions
});
