/*
 * @Auhtor: 文锋
 * @Date: 2021-05-17 15:10:51
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-17 15:11:02
 * @FilePath: \repair\src\api\service\broadbandService.js
 */
/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const repairList = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/list",
    method: "post",
    data: params
  });
};

export const repairEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/edit",
    method: "post",
    data: params
  });
};

export const repairAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/add",
    method: "post",
    data: params
  });
};

export const repairLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/load",
    method: "post",
    data: params
  });
};

export const repairDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/delete",
    method: "post",
    data: params
  });
};
// 指派
export const repairAssign = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/assign",
    method: "post",
    data: params
  });
};
// 回访记录
export const repairVisit = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/visit",
    method: "post",
    data: params
  });
};
// 所指派的人员名单
export const getAppRoleUsers = function(params) {
  return axios({
    url: BaseUrl + "members/app/getAppRoleUsers",
    method: "get",
    params: params
  });
};

// 获取派单状态
export const getStatusNum = function (params) {
  return axios({
    url: BaseUrl + 'operate/repair/getNum',
    method: 'post',
    data: params
  })
}

//
export const repairInfo = function (params) {
  return axios({
    url: BaseUrl + 'app/repair/load',
    method: 'post',
    data: paramsrepairAssign
  })
}


export default {
  repairList,
  repairAdd,
  repairEdit,
  repairLoad,
  repairDelete,
  repairAssign,
  repairVisit,
  getAppRoleUsers,
  getStatusNum,
  repairInfo
};
