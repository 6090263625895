/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const systemPopList = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/pop/list",
    method: "post",
    data: params
  });
};

export const systemPopAdd = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/pop/add",
    method: "post",
    data: params
  });
};

export const systemPopDelete = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/pop/delete",
    method: "post",
    data: params
  });
};

export const systemPopEdit = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/pop/edit",
    method: "post",
    data: params
  });
};

export const systemPopLoad = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/pop/load",
    method: "post",
    data: params
  });
};

export default {
  systemPopList,
  systemPopAdd,
  systemPopDelete,
  systemPopEdit,
  systemPopLoad
};
