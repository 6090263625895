/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const versionList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/version/list",
    method: "post",
    data: params
  });
};

export const versionAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/version/add",
    method: "post",
    data: params
  });
};

export const versionDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/version/delete",
    method: "post",
    data: params
  });
};

export const versionEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/version/edit",
    method: "post",
    data: params
  });
};

export const versionLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/version/load",
    method: "post",
    data: params
  });
};

export default {
  versionList,
  versionAdd,
  versionDelete,
  versionEdit,
  versionLoad,
};
