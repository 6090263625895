/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/organize/OrganizeTrends";

const state = {
  storeList: [],
  storeTotal: 0,
};

const mutations = {
  saveList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

// actions
const actions = {
  async getNewsList({ commit, state }, params) {
    let result = await api.getNewsList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async newsAdd({ commit, state }, params) {
    return await api.newsAdd(params);
  },
  async newsEdit({ commit, state }, params) {
    return await api.newsEdit(params);
  },
  async newsLoad({ commit, state }, params) {
    return await api.newsLoad(params);
  },
  async newsDelete({ commit, state }, params) {
    return await api.newsDelete(params);
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
