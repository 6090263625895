const TimeTable = () => import ('@/components/timeTable/TimeTable')
const ClassManage = () => import ('@/components/timeTable/ClassManage')
const TermManage = () => import ('@/components/timeTable/TermManage')

export default {
  route:[
    {
      path: "timeTable",
      name: "课程表",
      component: TimeTable
    },
    {
      path: "classManage",
      name: "节次管理",
      component: ClassManage
    },
    {
      path: "termManage",
      name: "学期管理",
      component: TermManage
    },
  ]
};

