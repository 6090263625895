/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const membersCampusList = function(params) {
  return axios({
    url: BaseUrl + "members/schoolDist/list",
    method: "post",
    data: params
  });
};

export const membersCampusAdd = function(params) {
  return axios({
    url: BaseUrl + "members/schoolDist/add",
    method: "post",
    data: params
  });
};

export const membersCampusDelete = function(params) {
  return axios({
    url: BaseUrl + "members/schoolDist/delete",
    method: "post",
    data: params
  });
};

export const membersCampusEdit = function(params) {
  return axios({
    url: BaseUrl + "members/schoolDist/edit",
    method: "post",
    data: params
  });
};

export default {
  membersCampusList,
  membersCampusAdd,
  membersCampusDelete,
  membersCampusEdit,
};