/* eslint-disable no-unused-vars */
const DealOrder = () => import ('@/components/finance/DealOrder')
const CommunOrder = () => import ('@/components/finance/CommunOrder')
const Deposit = () => import ('@/components/finance/Deposit')
export default {
  route:[
    {
      path: "dealOrder",
      name: "网络大厅订单",
      component: DealOrder
    },
    {
      path: "communOrder",
      name: "通信大厅订单",
      component: CommunOrder
    },
    {
      path: "deposit",
      name: "提现管理",
      component: Deposit
    }
  ]
};
