/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const membersSysList = function(params) {
  return axios({
    url: BaseUrl + "members/sys/list",
    method: "post",
    data: params
  });
};

export const membersSysAdd = function(params) {
  return axios({
    url: BaseUrl + "members/sys/add",
    method: "post",
    data: params
  });
};

export const membersSysDelete = function(params) {
  return axios({
    url: BaseUrl + "members/sys/delete",
    method: "post",
    data: params
  });
};

export const membersSysEdit = function(params) {
  return axios({
    url: BaseUrl + "members/sys/edit",
    method: "post",
    data: params
  });
};

export const membersSysGetAreaManager = function(params) {
  return axios({
    url: BaseUrl + "members/sys/getAreaManager",
    method: "post",
    data: params
  });
};
// 获取已维护学校的省市区树
export const getSchoolAreaTree = function(params) {
  return axios({
    url: BaseUrl + "members/school/getSchoolAreaTree",
    method: "post",
    data: params
  });
};

export const deptAll = function(params) {
  return axios({
    url: BaseUrl + "common/dept/all",
    method: "post",
    data: params
  });
};

export default {
  membersSysList,
  membersSysAdd,
  membersSysDelete,
  membersSysEdit,
  membersSysGetAreaManager,
  getSchoolAreaTree,
  deptAll
};
