/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/schoolNews";

const state = {
    schoolNewsList:[],
    schoolNewsTotal: 0,
};

// actions
const actions = {
  async getSchoolNewsList({ commit, state }, params) {
    let result = await api.postSchoolNewsList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 详情
  async getSchoolNewsLoad({ commit, state }, params) {
    return await api.postSchoolNewsLoad(params);
  },
  // 增加
  async getSchoolNewsAdd({ commit, state }, params) {
    return await api.postSchoolNewsAdd(params);
  },
  // 修改
  async getSchoolNewsEdit({ commit, state }, params) {
    return await api.postSchoolNewsEdit(params);
  },
  // 删除
  async getSchoolNewsDelete({ commit, state }, params) {
    return await api.postSchoolNewsDelete(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.schoolNewsList = list;
    state.schoolNewsTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
