/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/system/role";

const state = {
  roleList:[],
  roleTotal: 0,
};

// actions
const actions = {
  async commonRoleList({ commit, state }, params) {
    let result = await api.commonRoleList(params);
    commit("saveRoleList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async commonRoleAdd({ commit, state }, params) {
    return await api.commonRoleAdd(params);
  },
  async commonRoleDelete({ commit, state }, params) {
    return await api.commonRoleDelete(params);
  },
  async commonRoleEdit({ commit, state }, params) {
    return await api.commonRoleEdit(params);
  },
  async commonRoleAll({ commit, state }, params) {
    return await api.commonRoleAll(params);
  },
  async commonRoleMenuSelectAll({ commit, state }, params) {
    return await api.commonRoleMenuSelectAll(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveRoleList(state, { list, total }) {
    state.roleList = list;
    state.roleTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
