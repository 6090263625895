/* eslint-disable no-unused-vars */
const SchoolManage = () => import ('@/components/basicInfo/SchoolManage')
const CollegeManage = () => import ('@/components/basicInfo/CollegeManage')
const MemberManage = () => import ('@/components/basicInfo/MemberManage')
const SchoolManageCeo = () => import ('@/components/basicInfo/ceoManage/SchoolManageCeo')
const CollegeManageCeo = () => import ('@/components/basicInfo/ceoManage/CollegeManageCeo')
const MemberManageCeo = () => import ('@/components/basicInfo/ceoManage/MemberManageCeo')
export default {
  route:[
    // 区域经理、运营总监 
    {
      path: "schoolManage",
      name: "学校管理",
      component: SchoolManage
    },
    {
      path: "collegeManage",
      name: "学院管理",
      component: CollegeManage
    },
    {
      path: "memberManage",
      name: "成员管理",
      component: MemberManage
    },
    // CEO
    {
      path: "schoolManageCeo",
      name: "学校管理",
      component: SchoolManageCeo
    },
    {
      path: "collegeManageCeo",
      name: "学院管理",
      component: CollegeManageCeo
    },
    {
      path: "memberManageCeo",
      name: "成员管理",
      component: MemberManageCeo
    }
  ]
};
