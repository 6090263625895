/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/app";

const state = {
  appList:[],
  appTotal: 0,
};

// actions
const actions = {
  async membersAppList({ commit, state }, params) {
    let result = await api.membersAppList(params);
    commit("saveAppList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async membersAppEdit({ commit, state }, params) {
    return await api.membersAppEdit(params);
  },
  async membersAppAdd({ commit, state }, params) {
    return await api.membersAppAdd(params);
  },
  async membersAppAuth({ commit, state }, params) {
    return await api.membersAppAuth(params);
  },
  async membersAppLoad({ commit, state }, params) {
    return await api.membersAppLoad(params);
  },
  async commonDictGetDictFPid({ commit, state }, params) {
    return await api.commonDictGetDictFPid(params);
  },
  async membersAppRoleSelectAll({ commit, state }, params) {
    return await api.membersAppRoleSelectAll(params);
  },
  async membersAppRoleGrant({ commit, state }, params) {
    return await api.membersAppRoleGrant(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveAppList(state, { list, total }) {
    state.appList = list;
    state.appTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
