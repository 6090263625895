/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/suggestions";

const state = {
  suggestList:[],
  suggestTotal: 0,
};

// actions
const actions = {
  async adviseList({ commit, state }, params) {
    let result = await api.adviseList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async adviseLoad({ commit, state }, params) {
    return await api.adviseLoad(params);
  },
  async adviseFinish({ commit, state }, params) {
    return await api.adviseFinish(params);
  },
  async adviseDelete({ commit, state }, params) {
    return await api.adviseDelete(params);
  },
 
};

//getters
const getters = {};

const mutations = {
  saveList(state, { list, total }) {
    state.suggestList = list;
    state.suggestTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
