/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/treaty";

const state = {
  treatyList:[],
  treatyTotal: 0,
};

// actions
const actions = {
  async systemTreatyList({ commit, state }, params) {
    let result = await api.systemTreatyList(params);
    commit("saveTreatyList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async systemTreatyAdd({ commit, state }, params) {
    return await api.systemTreatyAdd(params);
  },
  async systemTreatyDelete({ commit, state }, params) {
    return await api.systemTreatyDelete(params);
  },
  async systemTreatyLoad({ commit, state }, params) {
    return await api.systemTreatyLoad(params);
  },
  async systemTreatyEdit({ commit, state }, params) {
    return await api.systemTreatyEdit(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveTreatyList(state, { list, total }) {
    state.treatyList = list;
    state.treatyTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
