//全局getters
export default {
  getPageParams: (state) => (pageName, useSessionStorage) => {
    if (state.pageParams[pageName]) {
      return state.pageParams[pageName];
    }
    if (useSessionStorage) {
      let str = sessionStorage.getItem("pageParams-" + pageName);
      if (str) return JSON.parse(str);
    }
    return null;
  },
  getEditableTabs: (state) => {
    return state.tabsList;
  }
};
