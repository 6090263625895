/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/enter/aduit";

const state = {
  settlList:[],
  settleTotal: 0,
};

// actions
const actions = {
  async settleList({ commit, state }, params) {
    let result = await api.settleList(params);
    result.list.forEach(k => { 
      let a = k.reviewers[0] ? k.reviewers[0].status : null
      let b = k.reviewers[1] ? k.reviewers[1].status : null
      let c = k.reviewers[2] ? k.reviewers[2].status : null
      k.setps = [
        {
          name: k.ceo, dept: 'CEO', reviewStatus:a, status: [
            { name: '待审核', value: 1, class:'on' },
            { name: '审核通过', value: 2, class:'finish' },
            { name: '未通过', value: -1, class:'fail' },
          ]
        },
        {
          name: k.qyjl, dept: '区域经理', reviewStatus:b, status: [
            { name: '待审核', value: 2, class:'on' },
            { name: '审核通过', value: 3, class:'finish' },
            { name: '未通过', value: -1, class:'fail' },
          ]
        },
        {
          name: k.yyzj, dept: '运营总监', reviewStatus:c, status: [
            { name: '待审核', value: 3, class:'on' },
            { name: '审核通过', value: 4, class:'finish' },
            { name: '未通过', value: -1, class:'fail' },
          ]
        },
      ]
    })
    // console.log(result.list)
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 添加
  async settleAdd({ commit, state }, params) {
    return await api.settleAdd(params);
  },
  async settleReview({ commit, state }, params) {
    return await api.settleReview(params);
  },
  async settleLoad({ commit, state }, params) {
    return await api.settleLoad(params);
  },
  async dictGetDictFPid({ commit, state }, params) {
    return await api.dictGetDictFPid(params);
  },
  async getSchoolListByCity({ commit, state }, params) {
    return await api.getSchoolListByCity(params);
  },
  async getLoginUser({ commit, state }, params) {
    return await api.getLoginUser(params);
  },
  async getAllDictTreeFPid({ commit, state }, params) {
    return await api.getAllDictTreeFPid(params);
  },
  
  
};

//getters
const getters = {};

const mutations = {
  saveList(state, { list, total }) {
    state.settlList = list;
    state.settleTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
