/* eslint-disable no-unused-vars */
const PartTime = () => import ('@/components/operate/PartTime')
const NewPart = () => import ('@/components/operate/NewPart')
const Schoolfellow = () => import ('@/components/operate/Schoolfellow')
const SchoolNews = () => import ('@/components/operate/SchoolNews')
const LittleMoney = () => import ('@/components/operate/LittleMoney')
const FleaMarket = () => import ('@/components/operate/FleaMarket')
const SchoolNewsAdd = () => import ('@/components/operate/SchoolNewsAdd')
const Report = () => import ('@/components/operate/Report')
const Advertising = () => import ('@/components/operate/Advertising')
const LostProperty = () => import ('@/components/operate/LostProperty')
const NewAdvertising = () => import ('@/components/operate/NewAdvertising')
const Cetab = () => import ('@/components/operate/Cetab')
const NetRepair = () => import ('@/components/operate/NetRepair')
const CampusRepair = () => import ('@/components/operate/CampusRepair')

export default {
  route:[
    {
      path: "partTime",
      name: "兼职管理",
      component: PartTime,
    },
    {
      path: "newPart",
      name: "新增兼职",
      component: NewPart
    },
    {
      path: "schoolfellow",
      name: "校友圈",
      component: Schoolfellow
    },
    {
      path: "schoolNews",
      name: "校园新闻",
      component: SchoolNews
    },
    {
      path: "schoolNewsAdd",
      name: "新增校园新闻",
      component: SchoolNewsAdd
    },
    {
      path: "littleMoney",
      name: "小赚一笔",
      component: LittleMoney
    },
    {
      path: "fleaMarket",
      name: "跳蚤市场",
      component: FleaMarket
    },
    {
      path: "report",
      name: "举报管理",
      component: Report
    },
    {
      path: "newAdvertising",
      name: "新增广告",
      component: NewAdvertising
    },
    {
      path: "advertising",
      name: "广告管理",
      component: Advertising
    },
    {
      path: "lostProperty",
      name: "失物招领",
      component: LostProperty
    },
    {
      path: "cetab",
      name: "失物招领",
      component: Cetab
    },
    {
      path: "netRepair",
      name: "宽带报修",
      component: NetRepair
    },
    {
      path: "campusRepair",
      name: "校园报修",
      component: CampusRepair
    },
  ]
};
