<template>
  <div id="app" class="page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style lang="scss">
  @import url("assets/scss/common.scss");
</style>