/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询end */
export const getNewsList = function(params) {
  return axios({
    url: BaseUrl + "club/news/app/getNewsAll",
    method: "post",
    data: params
  });
};

/** 添加 */
export const newsAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/clubNews/add",
    method: "post",
    data: params
  });
};

/** 编辑 */
export const newsEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/clubNews/edit",
    method: "post",
    data: params
  });
};

/** 详情 */
export const newsLoad = function(params) {
  return axios({
    url: BaseUrl + "club/news/app/loadNews",
    method: "post",
    data: params
  });
};

/** 删除 */
export const newsDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/clubNews/delete",
    method: "post",
    data: params
  });
};

export default {
  getNewsList,
  newsAdd,
  newsEdit,
  newsLoad,
  newsDelete,
};
