/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const systemGuideList = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/guiDePage/list",
    method: "post",
    data: params
  });
};

export const systemGuideAdd = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/guiDePage/add",
    method: "post",
    data: params
  });
};

export const systemGuideDelete = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/guiDePage/delete",
    method: "post",
    data: params
  });
};

export const systemGuideEdit = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/guiDePage/edit",
    method: "post",
    data: params
  });
};

export const systemGuideLoad = function(params) {
  return axios({
    url: BaseUrl + "operate//admin/guiDePage/load",
    method: "post",
    data: params
  });
};

export default {
  systemGuideList,
  systemGuideAdd,
  systemGuideDelete,
  systemGuideEdit,
  systemGuideLoad
};
