/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 校友圈列表
export const getSchoolFellowList = function(params) {
  return axios({
    url: BaseUrl + "social/my/getPagesList",
    method: "post",
    data: params
  });
};
// 详情
export const socialDetail = function(params) {
  return axios({
    url: BaseUrl + "social/my/detail",
    method: "post",
    data: params
  });
};

export default {
  getSchoolFellowList,
  socialDetail
};
