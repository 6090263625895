/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/operate/advertising";

const state = {
  advertList:[],
  advertTotal: 0,
};

// actions
const actions = {
  async getPagesList({ commit, state }, params) {
    let result = await api.getPagesList(params);
    commit("saveSchoolList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  // 添加
  async advertAdd({ commit, state }, params) {
    return await api.advertAdd(params);
  },
  async advertEdit({ commit, state }, params) {
    return await api.advertEdit(params);
  },
  async advertLoad({ commit, state }, params) {
    return await api.advertLoad(params);
  },
  async advertGetPosition({ commit, state }, params) {
    return await api.advertGetPosition(params);
  },
  async advertDelete({ commit, state }, params) {
    return await api.advertDelete(params);
  },
  async selectAllPosition({ commit, state }, params) {
    return await api.selectAllPosition(params);
  },
  async selectAllMajor({ commit, state }, params) {
    return await api.selectAllMajor(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.advertList = list;
    state.advertTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
