/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 举报列表
export const getPagesList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/report/getPagesList",
    method: "post",
    data: params
  });
};

export const reportGetDetail = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/report/getDetail",
    method: "post",
    data: params
  });
};

export const reportEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/report/edit",
    method: "post",
    data: params
  });
};

// number
export const getNumber = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/report/getNumber",
    method: "post",
    data: params
  });
};

export default {
  getPagesList,
  reportGetDetail,
  reportEdit,
  getNumber
};
