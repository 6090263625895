/* eslint-disable no-unused-vars */
import { mapMutations, mapGetters, mapActions } from "vuex";
import { log, mergeObject } from '@/common/utils'
export default {
  data: function() {
    return {
      isEditor: false,

      //加载loading
      pLoading: false,
      tLoading: false,
      fLoading: false,
      bLoading: false,

      //对话框显示开关
      aVisible: false,
      bVisible: false,
      cVisible: false,
      dVisible: false,
    };
  },
  computed: {
    ...mapGetters(["getPageParams"])
  },
  methods: {
    ...mapMutations(["savePageParams"]),
    async refresh(){ //列表查询
      this.tLoading = true
      this.savePageParams({
        pageName: this.pageName,
        params: this.queryParams
      });
      try {
        await this[this.pageApi](this.queryParams)
        // if (this.pageStatusApi) {
        //   con
        //   await this[this.pageStatusApi](this.queryParams) 
        // }
      }catch(e){
        log(e)
      }finally {
        this.tLoading = false;
      }
    },

    // 初始化表单-赋值
    handleForm(isEditor = false, row, afterFun = null) {
      this.aVisible = true;
      this.isEditor = isEditor
      this.$nextTick(async () => {
          this.$refs.formFileds.resetFields();
          this.bLoading = true
          try {
            if (isEditor) {
              if(this.loadApi){
                let res = await this[this.loadApi]({id:row.id})
                mergeObject(this.form, res)
                if(afterFun) afterFun(res);
              }else{
                mergeObject(this.form, row)
                if(afterFun) afterFun(row);
              }
            }else{
              if(afterFun) afterFun(row);
            }
          }catch(e){
            log(e)
          }finally {
            this.bLoading = false
          }
      })
    },

    // 编辑、添加表单提交
    async submitForm(api, beforeFun = null, afterFun = null) {
      let beforeValid = true;
      let params = Object.assign({}, this.form)
      if(beforeFun) beforeValid = beforeFun(params);
      if(beforeValid){
        this.$refs.formFileds.validate((valid) => {
          if (valid) {
            this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
              this.bLoading = true
              try {
                let res = await this[api](params)
                this.$message.success('数据保存成功！')
                this.aVisible = false
                if(afterFun){
                  res ? afterFun(res) : afterFun(params)
                }
                this.refresh()
              }catch(e){
                log(e)
              }finally {
                this.bLoading = false
              }
            })
          }
        })
      }
    },

    // 详情
    handleDetails(row, afterFun = null) {
      this.bVisible = true;
      this.$nextTick(async () => {
          this.$refs.detailsFileds.resetFields();
          this.bLoading = true
          try {
            if(this.loadApi){
              let res = await this[this.loadApi]({id:row.id})
              mergeObject(this.details, res)
              if(afterFun) afterFun(res);
            }else{
              mergeObject(this.details, row)
              if(afterFun) afterFun(row);
            }
          }catch(e){
            log(e)
          }finally {
            this.bLoading = false
          }
      })
    },

    handleDelete(para){
      this.$confirm('确定要删除该记录吗?', '信息', {
        type: 'warning'
      }).then(async () => {
        this.bLoading = true
        try {
          await this[this.deleteApi](para)
          this.refresh()
          this.$message.success('该记录已删除!')
        }catch(e){
          log(e)
        }finally {
          this.bLoading = false
        }
      })
    },

    resetPageAndRefresh() {
      this.queryParams.pageNum = 1;
      this.refresh();
    },

    toPage(path="/login", query={}){
      if (!path || this.$route.path == path) return;
      this.$router.push({ path: path, query: query })
    },
  }
};
