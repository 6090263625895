/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/timeTable/TermManage";

const state = {
  storeList:[],
  storeTotal: 0,
};

const mutations = {
  saveList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

// actions
const actions = {
  async semesterList({ commit, state }, params) {
    let result = await api.semesterList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async semesterAdd({ commit, state }, params) {
    return await api.semesterAdd(params);
  },
  async semesterEdit({ commit, state }, params) {
    return await api.semesterEdit(params);
  },
  async semesterLoad({ commit, state }, params) {
    return await api.semesterLoad(params);
  },
  async semesterDelete({ commit, state }, params) {
    return await api.semesterDelete(params);
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
