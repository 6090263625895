/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 小赚一笔列表
export const postLittleMoneyList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/task/getPagesList",
    method: "post",
    data: params
  });
};

// 详情
export const postLittleMoneyLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/task/getDetail",
    method: "post",
    data: params
  });
};
export default {
  postLittleMoneyList,
  postLittleMoneyLoad
};
