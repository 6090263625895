/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const commonRoleList = function(params) {
  return axios({
    url: BaseUrl + "common/role/list",
    method: "post",
    data: params
  });
};

export const commonRoleAll = function(params) {
  return axios({
    url: BaseUrl + "common/role/selectAll",
    method: "post",
    data: params
  });
};

export const commonRoleAdd = function(params) {
  return axios({
    url: BaseUrl + "common/role/add",
    method: "post",
    data: params
  });
};

export const commonRoleDelete = function(params) {
  return axios({
    url: BaseUrl + "common/role/delete",
    method: "post",
    data: params
  });
};

export const commonRoleEdit = function(params) {
  return axios({
    url: BaseUrl + "common/role/edit",
    method: "post",
    data: params
  });
};

export const commonRoleMenuSelectAll = function(params) {
  return axios({
    url: BaseUrl + "common/roleMenu/selectAll",
    method: "post",
    data: params
  });
};

export default {
  commonRoleList,
  commonRoleAdd,
  commonRoleAll,
  commonRoleDelete,
  commonRoleEdit,
  commonRoleMenuSelectAll
};
