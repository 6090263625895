/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const adviseList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advise/getPageList",
    method: "post",
    data: params
  });
};

export const adviseLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advise/detail",
    method: "post",
    data: params
  });
};

export const adviseFinish = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advise/edit",
    method: "post",
    data: params
  });
};

export const adviseDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advise/delete",
    method: "post",
    data: params
  });
};



export default {
  adviseList,
  adviseLoad,
  adviseFinish,
  adviseDelete,
};
