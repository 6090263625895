/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

// 列表
export const schoolFadeGetPageList = function(params) {
  return axios({
    url: BaseUrl + "members/school/fade/getPageList",
    method: "post",
    data: params
  });
};

// 校区选择
export const membersSchoolDistAppUserDists = function(params) {
  return axios({
    url: BaseUrl + "members/schoolDist/appUserDists",
    method: "post",
    data: params
  });
};

// 根据学校类型选择专业
export const commonDictList = function(params) {
  return axios({
    url: BaseUrl + "common/dict/list",
    method: "post",
    data: params
  });
};

// 添加学院专业
export const membersMajorAdd = function(params) {
  return axios({
    url: BaseUrl + "members/school/fade/add",
    method: "post",
    data: params
  });
};

// 列表搜索获取院系专业
export const membersMajorSelectAll = function(params) {
  return axios({
    url: BaseUrl + "members/major/selectAll",
    method: "post",
    data: params
  });
};

// 修改学院专业
export const membersMajorEdit = function(params) {
  return axios({
    url: BaseUrl + "members/school/fade/edit",
    method: "post",
    data: params
  });
};

// 删除院系
export const membersMajorDeleteFade = function(params) {
  return axios({
    url: BaseUrl + "members/school/fade/deleteFade",
    method: "post",
    data: params
  });
};

// 搜索获取校区内院系
export const membersSchoolFadeSelectAll = function(params) {
  return axios({
    url: BaseUrl + "members/school/fade/selectAll",
    method: "post",
    data: params
  });
};

export default {
  schoolFadeGetPageList,
  membersSchoolDistAppUserDists,
  commonDictList,
  membersMajorAdd,
  membersMajorSelectAll,
  membersMajorEdit,
  membersMajorDeleteFade,
  membersSchoolFadeSelectAll,
};