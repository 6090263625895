/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const sectionList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/section/list",
    method: "post",
    data: params
  });
};

export const sectionAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/section/add",
    method: "post",
    data: params
  });
};

export const sectionEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/section/edit",
    method: "post",
    data: params
  });
};

export const sectionLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/section/load",
    method: "post",
    data: params
  });
};

export const sectionDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/section/delete",
    method: "post",
    data: params
  });
};

export default {
  sectionList,
  sectionAdd,
  sectionEdit,
  sectionLoad,
  sectionDelete
};
