/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const settleList = function(params) {
  return axios({
    url: BaseUrl + "settle/settle/list",
    method: "post",
    data: params
  });
};

export const settleAdd = function(params) {
  return axios({
    url: BaseUrl + "settle/settle/add",
    method: "post",
    data: params
  });
};
// 审核
export const settleReview = function(params) {
  return axios({
    url: BaseUrl + "settle/settle/review",
    method: "post",
    data: params
  });
}
// 详情
export const settleLoad = function(params) {
  return axios({
    url: BaseUrl + "settle/settle/load",
    method: "post",
    data: params
  });
};
// 根据pid查子级查企业类型跟商铺类型
export const dictGetDictFPid = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getDictFPid",
    method: "post",
    data: params
  });
};
// 获取当前账号下市下的所有学校
export const getSchoolListByCity = function(params) {
  return axios({
    url: BaseUrl + "members/school/getSchoolListByCity",
    method: "post",
    data: params
  });
};
// 获取角色账号
export const getLoginUser = function(params) {
  return axios({
    url: BaseUrl + "members/repair/getLoginUser",
    method: "post",
    data: params
  }); 
};
// 
export const getAllDictTreeFPid = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getAllDictTreeFPid",
    method: "post",
    data: params
  });
};
export default {
  settleList,
  settleAdd,
  settleReview,
  settleLoad,
  dictGetDictFPid,
  getSchoolListByCity,
  getLoginUser,
  getAllDictTreeFPid
};
