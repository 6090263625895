/*
 * @Auhtor: 文锋
 * @Date: 2021-05-12 18:13:55
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-24 09:58:06
 * @FilePath: \repair\src\router\index.js
 */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

 const originalPush = Router.prototype.push;
 Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
 }

import { concat } from "lodash";
import { getAccessToken } from "@/common/utils";

import Home from "@/views/Home.vue";
import Page404 from "@/views/Page404.vue";
import Login from "@/components/account/Login.vue";

import views from "./views";
import system from "./system";
import basic from "./basic";
import task from "./task";
import basicInfo from "./basicInfo"
import operate from "./operate"
import timeTable from "./timeTable"
import organize from "./organize"
import assess from "./assess"
import market from "./market"
import enter from "./enter"
import finance from "./finance"
import service from "./service"
import order from "./order"

const modules = [
  views,
  system,
  basic,
  task,
  basicInfo,
  operate,
  timeTable,
  organize,
  assess,
  market,
  enter,
  finance,
  service,
  order
];

let config = {
  // mode: "history",
  routes: [
    {
      path: "/",
      component: Home,
      redirect: "/netRepairs",
      children:[]
    },
    { path: "/login", name: "登录", component: Login },
  ]
};
modules.forEach(function(m) {
  config.routes[0].children = concat(config.routes[0].children, m.route);
});

config.routes.push({
  // 会匹配所有路径
  path: "*",
  name: "404",
  component: Page404
});

const router = new Router(config);

router.beforeEach((to, from, next) => {
  if (to.path == '/netRepairs') {
    if (to.query.token) {
      let token = to.query.token
      console.log(token);
      token = token.replace('%20', " ")
      sessionStorage.setItem('accessToken', token)
      localStorage.setItem('accessToken', token)
    }
  }
  if (to.path != '/login' && !getAccessToken()) {
    next({
      path: "/login",
    });
  } else {
    next();
  }
})

export default router;
