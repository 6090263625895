/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const getSchoolCombo = function(params) {
  return axios({
    // url: BaseUrl + "members/school/getSchoolCombo",
    url: BaseUrl + "members/school/getUserSchools",
    method: "post",
    data: params
  });
};

export const contestMatchSelectAll = function(params) {
  return axios({
    url: BaseUrl + "match/admin/contest/match/selectAll",
    method: "post",
    data: params
  });
};

export const contestBannerAll = function(params) {
  return axios({
    url: BaseUrl + "match/admin/contest/banner/all",
    method: "post",
    data: params
  });
};

/** 获取学校筛选树 */
export const getSchoolTree = function(params) {
  return axios({
    url: BaseUrl + "members/school/getSchoolTree",
    method: "post",
    data: params
  });
};

export const dictGetDictFPid = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getDictFPid",
    method: "post",
    data: params
  });
};

export default {
  getSchoolCombo,
  contestMatchSelectAll,
  contestBannerAll,
  getSchoolTree,
  dictGetDictFPid
};
