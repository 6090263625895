/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const semesterList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/semester/list",
    method: "post",
    data: params
  });
};

export const semesterAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/semester/add",
    method: "post",
    data: params
  });
};

export const semesterEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/semester/edit",
    method: "post",
    data: params
  });
};

export const semesterLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/semester/load",
    method: "post",
    data: params
  });
};

export const semesterDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/semester/delete",
    method: "post",
    data: params
  });
};

export default {
  semesterList,
  semesterAdd,
  semesterEdit,
  semesterLoad,
  semesterDelete
};
