<template>
  <div class="user-menu">
    <el-dropdown>
      <span class="el-dropdown-link">
        {{ userName }}
        <i class="el-icon-arrow-down"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>修改密码</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { log, getEnv } from "@/common/utils.js";
import { mapState, mapActions } from "vuex";
export default {
  name: "dropdownMenu",
  data() {
    return {
      userName: "",
    };
  },
  methods: {
    
  },
  mounted() {
    
  }
};
</script>