/* eslint-disable no-unused-vars */
const AssessCenter = () => import ('@/components/assess/AssessCenter')
const Divide = () => import ('@/components/assess/Divide')
const addAssess = () => import ('@/components/assess/addAssess')
const addDivide = () => import ('@/components/assess/addDivide')


export default {
  route:[
    {
      path: "AssessCenter",
      name: "考核权重",
      component: AssessCenter
    },
    {
      path: "divide",
      name: "分成管理",
      component: Divide
    },
    {
      path: "addAssess",
      name: "分成管理",
      component: addAssess
    },
    {
      path: "addDivide",
      name: "分成管理",
      component: addDivide
    },
  ]
};
