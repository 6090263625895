/* eslint-disable no-unused-vars */
const OrganizeTrends = () => import ('@/components/organize/OrganizeTrends')
const OrganizeActivity = () => import ('@/components/organize/OrganizeActivity')
const OrganizeManage = () => import ('@/components/organize/OrganizeManage')

export default {
  route:[
    {
      path: "organizeTrends",
      name: "社团动态",
      component: OrganizeTrends
    },
    {
      path: "organizeActivity",
      name: "社团活动",
      component: OrganizeActivity
    },
    {
      path: "organizeManage",
      name: "社团管理",
      component: OrganizeManage
    },
  ]
};
