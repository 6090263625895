/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const getTeamUserList = function(params) {
  return axios({
    url: BaseUrl + "members/app/getTeamUserList",
    method: "post",
    data: params
  });
};

export const addTeamUser = function(params) {
  return axios({
    url: BaseUrl + "members/app/addTeamUser",
    method: "post",
    data: params
  });
};

export const deleteTeamUser = function(params) {
  return axios({
    url: BaseUrl + "members/app/deleteTeamUser",
    method: "post",
    data: params
  });
};

export const editTeamUser = function(params) {
  return axios({
    url: BaseUrl + "members/app/editTeamUser",
    method: "post",
    data: params
  });
};
// 通过手机号查找成员
export const getSchoolUser = function(params) {
  return axios({
    url: BaseUrl + "members/app/getSchoolUser",
    method: "post",
    data: params
  });
};


export default {
  getTeamUserList,
  addTeamUser,
  deleteTeamUser,
  editTeamUser,
  getSchoolUser,
};
