/* eslint-disable no-unused-vars */
const CampuRepair = () => import ('@/components/service/CampusRepair')
const NetRepairs = () => import ('@/components/service/NetRepair')
const CampusRepairUser = () => import ('@/components/service/CampusRepairUser')
const NetRepairUser = () => import ('@/components/service/NetRepairUser')
export default {
  route:[
    {
      path: "campuRepair",
      name: "物业报修",
      component: CampuRepair
    },
    {
      path: "netRepairs",
      name: "网络报修",
      component: NetRepairs
    },
    {
      path: "campusRepairUser",
      name: "物业维修员",
      component: CampusRepairUser
    },
    {
      path: "netRepairUser",
      name: "网络维修员",
      component: NetRepairUser
    }
  ]
};
