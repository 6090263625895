/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const courseList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/course/list",
    method: "post",
    data: params
  });
};

export default {
  courseList,
};
