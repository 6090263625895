/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const commonDictGetDictFCode = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getDictFCode",
    method: "post",
    data: params
  });
};

export const commonDictGetDictFPid = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getDictFPid",
    method: "post",
    data: params
  });
};

export const commonDictGetAllDictFCode = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getAllDictFCode",
    method: "post",
    data: params
  });
};

export const commonDictGetAllDictFPid = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getAllDictFPid",
    method: "post",
    data: params
  });
};

export const commonDictGetAllDictTreeFCode = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getAllDictTreeFCode",
    method: "post",
    data: params
  });
};

export const commonDictGetAllDictTreeFPid = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getAllDictTreeFPid",
    method: "post",
    data: params
  });
};

export const commonDictList = function(params) {
  return axios({
    url: BaseUrl + "common/dict/list",
    method: "post",
    data: params
  });
};

export const commonDictAdd = function(params) {
  return axios({
    url: BaseUrl + "common/dict/add",
    method: "post",
    data: params
  });
};

export const commonDictDelete = function(params) {
  return axios({
    url: BaseUrl + "common/dict/delete",
    method: "post",
    data: params
  });
};

export const commonDictEdit = function(params) {
  return axios({
    url: BaseUrl + "common/dict/edit",
    method: "post",
    data: params
  });
};

export default {
  commonDictGetDictFCode,
  commonDictGetDictFPid,
  commonDictGetAllDictFCode,
  commonDictGetAllDictFPid,
  commonDictGetAllDictTreeFCode,
  commonDictGetAllDictTreeFPid,
  commonDictList,
  commonDictAdd,
  commonDictDelete,
  commonDictEdit
};
