/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/organize/OrganizeActivity";

const state = {
  storeList:[],
  storeTotal: 0,
};

const mutations = {
  saveList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

// actions
const actions = {
  async getActivityList({ commit, state }, params) {
    let result = await api.getActivityList(params);
    commit("saveList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async prizeList({ commit, state }, params) {
    return await api.prizeList(params);
  },
  async activityAdd({ commit, state }, params) {
    return await api.activityAdd(params);
  },
  async activityEdit({ commit, state }, params) {
    return await api.activityEdit(params);
  },
  async activityLoad({ commit, state }, params) {
    return await api.activityLoad(params);
  },
  async activityGetUserList({ commit, state }, params) {
    return await api.activityGetUserList(params);
  },
  async activityGetClockList({ commit, state }, params) {
    return await api.activityGetClockList(params);
  },
  async activityDelete({ commit, state }, params) {
    return await api.activityDelete(params);
  },
};

//getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
