/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 用户列表 */
export const membersAppList = function(params) {
  return axios({
    url: BaseUrl + "members/app/list",
    method: "post",
    data: params
  });
};

/** 用户添加 */
export const membersAppAdd = function(params) {
  return axios({
    url: BaseUrl + "members/app/add",
    method: "post",
    data: params
  });
};

/** 用户编辑 */
export const membersAppEdit = function(params) {
  return axios({
    url: BaseUrl + "members/app/edit",
    method: "post",
    data: params
  });
};

/** 用户详情 */
export const membersAppLoad = function(params) {
  return axios({
    url: BaseUrl + "members/app/load",
    method: "post",
    data: params
  });
};

/** 实名认证 */
export const membersAppAuth = function(params) {
  return axios({
    url: BaseUrl + "members/app/auth",
    method: "post",
    data: params
  });
};

/** 分配角色 */
export const commonDictGetDictFPid = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getDictFPid",
    method: "post",
    data: params
  });
};

/** 查找所有的app角色 */
export const membersAppRoleSelectAll = function(params) {
  return axios({
    url: BaseUrl + "members/appRole/selectAll",
    method: "post",
    data: params
  });
};

/** 对app用户分配app角色 */
export const membersAppRoleGrant = function(params) {
  return axios({
    url: BaseUrl + "members/appRole/grant",
    method: "post",
    data: params
  });
};

export default {
  membersAppEdit,
  membersAppAdd,
  membersAppList,
  membersAppAuth,
  membersAppLoad,
  commonDictGetDictFPid,
  membersAppRoleSelectAll,
  membersAppRoleGrant,
};
