/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 广告列表
export const getPagesList = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advert/getPagesList",
    method: "post",
    data: params
  });
};


export const advertAdd = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advert/add",
    method: "post",
    data: params
  });
};

export const advertEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advert/edit",
    method: "post",
    data: params
  });
};

export const advertLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advert/load",
    method: "post",
    data: params
  });
};

/** 根据发布类型 获取广告位end */
export const advertGetPosition = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advertPosition/getPosition",
    method: "post",
    data: params
  });
};

/** 删除end */
export const advertDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advert/delete",
    method: "post",
    data: params
  });
};

/** 获取所有广告位end */
export const selectAllPosition = function(params) {
  return axios({
    url: BaseUrl + "operate/admin/advertPosition/selectAll",
    method: "post",
    data: params
  });
};

/** 获取所有广告位end */
export const selectAllMajor = function(params) {
  return axios({
    url: BaseUrl + "common/dict/selectAll",
    method: "post",
    data: params
  });
};

export default {
  getPagesList,
  advertAdd,
  advertEdit,
  advertLoad,
  advertDelete,
  advertGetPosition,
  selectAllPosition,
  selectAllMajor
};
