/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/basic/version";

const state = {
  versionList:[],
  versionTotal: 0,
};

// actions
const actions = {
  async versionMList({ commit, state }, params) {
    let result = await api.versionList(params);
    commit("saveVersionList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async versionAdd({ commit, state }, params) {
    return await api.versionAdd(params);
  },
  async versionDelete({ commit, state }, params) {
    return await api.versionDelete(params);
  },
  async versionLoad({ commit, state }, params) {
    return await api.versionLoad(params);
  },
  async versionEdit({ commit, state }, params) {
    return await api.versionEdit(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveVersionList(state, { list, total }) {
    state.versionList = list;
    state.versionTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
